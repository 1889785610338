.ps-document-file {
    background: #eeeeee;
    padding: 0;
    position: relative;
    width: 180px;
    max-width: 180px;
    margin: 10px;
    flex-basis: auto;

    .__document-image {
        position: relative;
        padding: 5px;

        .__image {
            width: 100%;
            background-repeat: no-repeat;
            height: 200px;
            background-size: cover;
        }
    }

    .__document-meta {
        color: #000;
        text-align: left;
        width: 100%;
        padding: 5px;
        display: flex;
        align-items: center;
        @include easing-animation(backgroud);

        .__icon {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            color: $ps-info-color;
            @include easing-animation(backgroud);
        }

        .__title {
            margin-left: 5px;
        }
    }

    .__overlay {
        display: flex;
        width: 100%;
        height: 100%;
        background: rgba(#000000, 0.8);
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        @include easing-animation();

        .__icon {
            display: block;
            color: #fff;
            text-align: center;
            cursor: pointer;
            margin: 15px;
        }
    }

    &:hover {
        .__overlay {
            opacity: 1;
        }

        .__document-meta {
            background-color: $ps-edit-color;
            color: #fff;

            .__icon {
                background-color: #fff;
            }
        }
    }
}

