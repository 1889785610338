/* Customize tab */

p-tabPanel {
    > .ui-tabview-panel {
        padding: 15px;
    }

    &.no-padding {
        > .ui-tabview-panel {
            padding: 0;
        }
    }
}

.ps-form-container {
    .__modal-tab-title {
        text-transform: uppercase;
        color: $ps-edit-color;
        margin: 5px 0 15px;
    }
}
