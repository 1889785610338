button {
    &:disabled {
        cursor: not-allowed;
    }
}

.ps-btn {
    outline: none;
    cursor: pointer;
    height: 24px;
    font-size: inherit;

    .__icon {
        @include easing-animation();
    }

    &.icon-only {
        border: 0;
        background-color: transparent;
        padding: 0;
        width: 24px;

        .__icon {
            display: block;
            font-size: 16px;
        }
    }

    &.easing {
        @include easing-animation();
    }

    &.no-border {
        border: 0;
    }

    &.default-border {
        border: 1px solid #ddd;
    }
}

td > .ps-table-row-actions {
    margin-top: -5px;
    margin-bottom: -5px;
}

.ps-table-row-actions {
    .ps-btn {
        margin: 1px;
    }
}

.ps-btn.ps-top-table-btn,
.ps-btn.ps-modal-btn {
    position: relative;
    padding-left: 26px;
    padding-right: 10px;
    border-radius: 2px;

    &.no-icon {
        padding-left: 10px;
    }

    .__icon {
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        font-size: 14px;
        width: 26px;
        padding-top: 4px;
    }

    &.middle-icon {
        .__icon {
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.ps-btn.ps-top-table-btn {
    background: transparent;
    border: 1px solid transparent;

    @include easing-animation(border-color);

    &.trigger-dropdown {
        padding-right: 20px;
    }

    .__icon {
        padding-top: 4px;
    }

    .__dropdown-hint {
        position: absolute;
        top: 0;
        height: 100%;
        color: #888;
        right: 0;
        width: 20px;
        padding-top: 6px;
    }

    &.right-icon {
        padding-left: 10px;
        padding-right: 26px;

        .__icon {
            right: 0;
            left: auto;
        }
    }

    &:hover {
        border-color: #ddd;
    }
}

.ps-btn.ps-modal-btn {
    border: 0;
    color: #fff;
    @include easing-animation(background-color);
}

.ps-modal-more-action-btn,
.ps-advance-actions-btn {
    border:1px solid #aaa;
    background-color: #fff;
    border-radius: 2px;
    width: 24px;
}

@each $action, $value in (theme-blue: $ps-theme-blue-color ,base: $ps-base-color, info: $ps-info-color, danger: $ps-danger-color, edit: $ps-edit-color,
    remove: $ps-remove-color, restore: $ps-restore-color, back: $ps-back-color,
    lock: $ps-lock-color, unlock: $ps-unlock-color, print: $ps-print-color, config: $ps-config-color, report: $ps-report-color,
    validate: $ps-validate-color, view: $ps-view-color, data: $ps-data-color, light: #fff, auto: $ps-auto-color, scheduled: $ps-scheduled-color,
prioritize: $ps-prioritize-color) {

    // color
    .ps-#{$action}-color,
    .ps-#{$action}-hover-color.activated,
    .ps-#{$action}-hover-color:hover {
        color: $value;
    }

    .ps-#{$action}-color-i,
    .ps-#{$action}-hover-color-i.activated,
    .ps-#{$action}-hover-color-i:hover {
        color: $value !important;
    }

    .ps-#{$action}-color-lighter,
    .ps-#{$action}-hover-color-lighter.activated,
    .ps-#{$action}-hover-color-lighter:hover {
        color: lighten($value, 10%);
    }

    .ps-#{$action}-color-lighter-i,
    .ps-#{$action}-hover-color-lighter-i.activated,
    .ps-#{$action}-hover-color-lighter-i:hover {
        color: lighten($value, 10%) !important;
    }

    .ps-#{$action}-color-bolder,
    .ps-#{$action}-hover-color-bolder.activated,
    .ps-#{$action}-hover-color-bolder:hover {
        color: darken($value, 10%);
    }

    .ps-#{$action}-color-bolder-i,
    .ps-#{$action}-hover-color-bolder-i.activated,
    .ps-#{$action}-hover-color-bolder-i:hover {
        color: darken($value, 10%) !important;
    }

    // bgc
    .ps-#{$action}-bgc,
    .ps-#{$action}-hover-bgc.activated,
    .ps-#{$action}-hover-bgc:hover {
        background-color: $value;
    }

    .ps-#{$action}-bgc-i,
    .ps-#{$action}-hover-bgc-i.activated,
    .ps-#{$action}-hover-bgc-i:hover {
        background-color: $value !important;
    }

    .ps-#{$action}-bgc-lighter,
    .ps-#{$action}-hover-bgc-lighter.activated,
    .ps-#{$action}-hover-bgc-lighter:hover {
        background-color: lighten($value, 10%);
    }

    .ps-#{$action}-bgc-lighter-i,
    .ps-#{$action}-hover-bgc-lighter-i.activated,
    .ps-#{$action}-hover-bgc-lighter-i:hover {
        background-color: lighten($value, 10%) !important;
    }

    .ps-#{$action}-bgc-bolder,
    .ps-#{$action}-hover-bgc-bolder.activated,
    .ps-#{$action}-hover-bgc-bolder:hover {
        background-color: darken($value, 10%);
    }

    .ps-#{$action}-bgc-bolder-i,
    .ps-#{$action}-hover-bgc-bolder-i.activated,
    .ps-#{$action}-hover-bgc-bolder-i:hover {
        background-color: darken($value, 10%) !important;
    }

    // button

    .ps-btn-action-#{$action}:not(.ps-modal-btn) {
        .__icon {
            color: $value;
        }

        &:hover {
            .__icon {
                color: darken($value, 10%);
            }
        }
    }

    .ps-btn-action-#{$action}.ps-modal-btn {
        background-color: $value;

        &:hover {
            background-color: darken($value, 10%);
        }
    }
}

.ps-bulk-select-wrapper {
    position: relative;
    border: 1px solid #ddd;
    display: inline-block;
    padding-right: 22px;
    height: 24px;
    padding-top: 3px;
    padding-left: 3px;

    .ps-btn.ps-bulk-select-btn {
        position: absolute;
        height: 100%;
        width: 22px;
        font-size: 14px;
        color: #666;
        top: 0;
        right: 0;
        border: 0;
        background-color: transparent;
    }
}

body .custom-autocomplete-button {
    font-size: 0.8em;
    width    : 22px;
}

.button-group button {
    margin-bottom: 0.5em;
}


