.ps-table-scheduled-container {
    .ui-table .ui-table-tbody > tr > td {
        &.__item-tick {
            cursor: pointer;
        }

        @each $name, $value in (busy:$ps-table-scheduled-busy, click:$ps-table-scheduled-click,
        selected:$ps-table-scheduled-selected,
        suggested:$ps-table-scheduled-suggested,
        suggestions: $ps-table-scheduled-suggestions) {
            &.__item-#{$name} {
                background-color: $value;
                position: relative;
            }
        }
    }
}
