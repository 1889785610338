.ps-table-calendar-container {
    .__top-table-action {
        .__right-content {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;

            .__current-time, .__navigation, .__view-type {
                display: inline-flex;
                padding: 0 7.5px;
                align-items: center;
                margin-bottom: 5px;
            }

            .__current-time, .__navigation {
                border-right: 1px solid #999999;
            }

            .__navigation {
                .ps-btn .__icon {
                    font-size: 20px;
                }
            }

            .__change-view {
                .ps-btn {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.ps-table-calendar-not-hover {
    .ui-table .ui-table-tbody > tr,
    .ui-table .ui-table-tbody > tr:nth-child(even) {
        background-color: #fff;

        &:hover {
            background-color: #fff;
        }
    }
}

.ps-table-calendar {
    thead.ui-table-thead {
        background: #eee;
    }

    .ui-table .ui-table-thead > tr > th {
        padding: 0;
    }

    .ui-table .ui-table-tbody > tr > td.__room-info {
        background-color: $ps-table-calendar-status-color-1;
    }

    .ui-table .ui-table-tbody > tr > td.__data-month {
        padding: 0;
        vertical-align: top;
    }

    td.__data-month {

        .__day {
            text-align: right;
            font-size: 16px;
            padding: 8px 10px;
        }

        .__period-info {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .__period {
                color: $ps-table-calendar-period-color;
                font-weight: normal;
                font-size: inherit;
                padding-left: 3px;
                width: 30%;
            }
        }

        .__room-info {
            background-color: $ps-table-calendar-status-color-1;
            margin-bottom: 3px;
            padding: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .ui-table .ui-table-thead > tr > th,
    .ui-table .ui-table-tfoot > tr > td {
        border-color: $ps-table-calendar-border-color;
    }

    .__day_of_week {
        display: flex;
        align-items: center;

        .__left, .__right {
            width: 50%;
            padding: 10px;

            &.__header-day {
                width: auto;
            }
        }

        .__left {
            font-size: 22px;
            color: #333333;
        }

        .__right {
        }
    }

    .__period-vs-day {
        position: relative;
        height: 70px;

        .__stroke {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;

            line {
                stroke: $ps-table-calendar-border-color;
                stroke-width: 1;
            }
        }

        .__period, .__day {
            height: 50%;
            padding: 10px;
            line-height: 15px;
            font-weight: bold;
            color: #000;
        }

        .__period {
            text-align: left;
        }

        .__day {
            text-align: right;
        }
    }

    td .__period {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
    }
}

.ps-table-calendar-room {
    .ui-table .ui-table-thead > tr > th {
        color: #000;
    }

    .ui-table .ui-table-tbody > tr > td.__data-room {
        padding: 0;
    }

    .__day-of-week {
        font-size: 18px;
    }

    .__period-info {
        border: 1px solid #ddd;
        padding: 5px;
        min-height: 30px;

        @each $name, $value in (dhcq:$ps-table-calendar-room-period-dhcq, sdh:$ps-table-calendar-room-period-sdh,
            vlvh:$ps-table-calendar-room-period-vlvh, data: $ps-table-calendar-room-period-data) {
            &.__period-info-#{$name} {
                background-color: $value;
            }
        }

        &.__period-info-exist {
            border: none;

            .__icon {
                color: $ps-edit-color;
                display: none;
            }

            &:hover {
                .__icon {
                    display: inline;
                }
            }
        }
    }

    .ui-table .ui-table-tbody > tr > td {
        @each $name, $value in (dhcq:$ps-table-calendar-room-period-dhcq, sdh:$ps-table-calendar-room-period-sdh,
            vlvh:$ps-table-calendar-room-period-vlvh, data: $ps-table-calendar-room-period-data,
            choose: $ps-table-calendar-room-period-data, drag: $ps-table-calendar-room-period-drag,
            busy: $ps-table-calendar-room-period-busy) {
            &.__period-info-#{$name} {
                background-color: $value;
                position: relative;
            }
        }

        &.__period-info-drag{
            cursor: pointer;
        }

        &.__period-info-data {
            color: #fff;
            font-weight: bold;
            cursor: pointer;


            &.active:after {
                content: "\f058";
                font: normal normal normal 14px/1 FontAwesome;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}


.__period-active {
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    background-color: $ps-table-calendar-room-period-data;
    border: none;
    outline: none;
}
