/* You can add global styles to this file, and also import other style files */
body {
  font-size: 12px; }

body.landing-body {
  background-color: #fff; }

p, h1, h2, h3, h4, h5, h6, ul {
  margin-top: 0;
  margin-bottom: 20px; }

input, textarea, button, select {
  outline: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

a {
  outline: none;
  text-decoration: none; }

body .ui-widget, body .ui-widget .ui-widget {
  font-family: inherit; }

.hidden, [hidden] {
  display: none !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media only screen and (min-width: 1025px) {
  .hidden-on-desktop {
    display: none; }
  .hidden-on-desktop-i {
    display: none !important; } }

@media only screen and (max-width: 1024px) {
  .hidden-on-small-device {
    display: none; }
  .hidden-on-small-device-i {
    display: none !important; } }

.block-el {
  display: block; }

.inline-block-el {
  display: inline-block; }

.flex-el {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .flex-el.align-content-top {
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start; }
  .flex-el.align-content-middle {
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center; }
  .flex-el.align-content-bottom {
    -ms-flex-align: end;
    -webkit-align-items: flex-end;
    align-items: flex-end; }
  .flex-el.align-content-center {
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
  .flex-el.align-content-left {
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start; }
  .flex-el.align-content-right {
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end; }
  .flex-el.space-between-content {
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
  .flex-el.space-around-content {
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    justify-content: space-around; }
  .flex-el.wrap-content {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .flex-el.x-direction {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
  .flex-el.x-reverse-direction {
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
  .flex-el.y-direction {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .flex-el.y-reverse-direction {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse; }

.block-center {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.cursor-pointer {
  cursor: pointer; }

.cursor-not-allowed {
  cursor: not-allowed; }

.overflow-hidden {
  overflow: hidden; }

.overflow-auto {
  overflow: auto; }

.overflow-x-auto {
  overflow-x: auto; }

.overflow-y-auto {
  overflow-y: auto; }

.overflow-x-hidden {
  overflow-x: hidden; }

.overflow-y-hidden {
  overflow-y: hidden; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-underline,
.text-hover-underline.activated,
.text-hover-underline:hover {
  text-decoration: underline; }

.text-line-through {
  text-decoration: line-through; }

.fs-inherit {
  font-style: inherit; }

.fs-italic {
  font-style: italic; }

.fs-normal {
  font-style: normal; }

.vertical-align-top {
  vertical-align: top; }

.vertical-align-middle {
  vertical-align: middle; }

.vertical-align-bottom {
  vertical-align: bottom; }

.align-left,
.ui-text-left {
  text-align: left; }

.align-center,
.ui-text-center {
  text-align: center; }

.align-right,
.ui-text-right {
  text-align: right; }

@media only screen and (max-width: 640px) {
  .align-sm-left,
  .ui-text-sm-left {
    text-align: left; }
  .align-sm-center,
  .ui-text-sm-center {
    text-align: center; }
  .align-sm-right,
  .ui-text-sm-right {
    text-align: right; } }

@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .align-md-left,
  .ui-text-md-left {
    text-align: left; }
  .align-md-center,
  .ui-text-md-center {
    text-align: center; }
  .align-md-right,
  .ui-text-md-right {
    text-align: right; } }

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .align-lg-left,
  .ui-text-lg-left {
    text-align: left; }
  .align-lg-center,
  .ui-text-lg-center {
    text-align: center; }
  .align-lg-right,
  .ui-text-lg-right {
    text-align: right; } }

@media only screen and (min-width: 1441px) {
  .align-xl-left,
  .ui-text-xl-left {
    text-align: left; }
  .align-xl-center,
  .ui-text-xl-center {
    text-align: center; }
  .align-xl-right,
  .ui-text-xl-right {
    text-align: right; } }

.txtRight {
  text-align: right; }

.fw-normal, .font-weight-normal {
  font-weight: normal; }

.prevent-line-break {
  white-space: nowrap; }

.break-word {
  word-wrap: break-word; }

.fw-inherit {
  font-weight: inherit; }

.fw-inherit-i {
  font-weight: inherit !important; }

.fw-light {
  font-weight: 300; }

.fw-light-i {
  font-weight: 300 !important; }

.fw-regular {
  font-weight: 400; }

.fw-regular-i {
  font-weight: 400 !important; }

.fw-medium {
  font-weight: 500; }

.fw-medium-i {
  font-weight: 500 !important; }

.fw-semibold {
  font-weight: 600; }

.fw-semibold-i {
  font-weight: 600 !important; }

.fw-bold {
  font-weight: 700; }

.fw-bold-i {
  font-weight: 700 !important; }

.ui-g-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px; }

[class^="ui-g-"], [class*=" ui-g-"],
[class^="ui-md-"], [class*=" ui-md-"],
[class^="ui-lg-"], [class*=" ui-lg-"],
[class^="ui-xl-"], [class*=" ui-xl-"] {
  padding: 0 7.5px; }
  [class^="ui-g-"].ui-g-nopad, [class*=" ui-g-"].ui-g-nopad,
  [class^="ui-md-"].ui-g-nopad, [class*=" ui-md-"].ui-g-nopad,
  [class^="ui-lg-"].ui-g-nopad, [class*=" ui-lg-"].ui-g-nopad,
  [class^="ui-xl-"].ui-g-nopad, [class*=" ui-xl-"].ui-g-nopad {
    padding: 0; }

.ui-g.row {
  margin-left: -7.5px;
  margin-right: -7.5px; }
  .ui-g.row.large-gutter {
    margin-left: -15px;
    margin-right: -15px; }

.ui-g.large-gutter [class^="ui-g-"], .ui-g.large-gutter [class*=" ui-g-"],
.ui-g.large-gutter [class^="ui-md-"], .ui-g.large-gutter [class*=" ui-md-"],
.ui-g.large-gutter [class^="ui-lg-"], .ui-g.large-gutter [class*=" ui-lg-"],
.ui-g.large-gutter [class^="ui-xl-"], .ui-g.large-gutter [class*=" ui-xl-"] {
  padding-left: 15px;
  padding-right: 15px; }
  .ui-g.large-gutter [class^="ui-g-"].ui-g-nopad, .ui-g.large-gutter [class*=" ui-g-"].ui-g-nopad,
  .ui-g.large-gutter [class^="ui-md-"].ui-g-nopad, .ui-g.large-gutter [class*=" ui-md-"].ui-g-nopad,
  .ui-g.large-gutter [class^="ui-lg-"].ui-g-nopad, .ui-g.large-gutter [class*=" ui-lg-"].ui-g-nopad,
  .ui-g.large-gutter [class^="ui-xl-"].ui-g-nopad, .ui-g.large-gutter [class*=" ui-xl-"].ui-g-nopad {
    padding: 0; }

.clearfix::after {
  content: "";
  clear: both;
  display: table; }

@media (max-width: 1024px) {
  .txtRight {
    text-align: left; } }

.m-5, .my-5, .mt-5 {
  margin-top: 5px; }

.m-5, .my-5, .mb-5 {
  margin-bottom: 5px; }

.m-5, .mx-5, .ml-5 {
  margin-left: 5px; }

.m-5, .mx-5, .mr-5 {
  margin-right: 5px; }

.m-10, .my-10, .mt-10 {
  margin-top: 10px; }

.m-10, .my-10, .mb-10 {
  margin-bottom: 10px; }

.m-10, .mx-10, .ml-10 {
  margin-left: 10px; }

.m-10, .mx-10, .mr-10 {
  margin-right: 10px; }

.m-15, .my-15, .mt-15 {
  margin-top: 15px; }

.m-15, .my-15, .mb-15 {
  margin-bottom: 15px; }

.m-15, .mx-15, .ml-15 {
  margin-left: 15px; }

.m-15, .mx-15, .mr-15 {
  margin-right: 15px; }

.m-20, .my-20, .mt-20 {
  margin-top: 20px; }

.m-20, .my-20, .mb-20 {
  margin-bottom: 20px; }

.m-20, .mx-20, .ml-20 {
  margin-left: 20px; }

.m-20, .mx-20, .mr-20 {
  margin-right: 20px; }

.m-25, .my-25, .mt-25 {
  margin-top: 25px; }

.m-25, .my-25, .mb-25 {
  margin-bottom: 25px; }

.m-25, .mx-25, .ml-25 {
  margin-left: 25px; }

.m-25, .mx-25, .mr-25 {
  margin-right: 25px; }

.m-30, .my-30, .mt-30 {
  margin-top: 30px; }

.m-30, .my-30, .mb-30 {
  margin-bottom: 30px; }

.m-30, .mx-30, .ml-30 {
  margin-left: 30px; }

.m-30, .mx-30, .mr-30 {
  margin-right: 30px; }

.m-n-5, .my-n-5, .mt-n-5 {
  margin-top: -5px; }

.m-n-5, .my-n-5, .mb-n-5 {
  margin-bottom: -5px; }

.m-n-5, .mx-n-5, .ml-n-5 {
  margin-left: -5px; }

.m-n-5, .mx-n-5, .mr-n-5 {
  margin-right: -5px; }

.m-n-10, .my-n-10, .mt-n-10 {
  margin-top: -10px; }

.m-n-10, .my-n-10, .mb-n-10 {
  margin-bottom: -10px; }

.m-n-10, .mx-n-10, .ml-n-10 {
  margin-left: -10px; }

.m-n-10, .mx-n-10, .mr-n-10 {
  margin-right: -10px; }

.m-n-15, .my-n-15, .mt-n-15 {
  margin-top: -15px; }

.m-n-15, .my-n-15, .mb-n-15 {
  margin-bottom: -15px; }

.m-n-15, .mx-n-15, .ml-n-15 {
  margin-left: -15px; }

.m-n-15, .mx-n-15, .mr-n-15 {
  margin-right: -15px; }

.m-n-20, .my-n-20, .mt-n-20 {
  margin-top: -20px; }

.m-n-20, .my-n-20, .mb-n-20 {
  margin-bottom: -20px; }

.m-n-20, .mx-n-20, .ml-n-20 {
  margin-left: -20px; }

.m-n-20, .mx-n-20, .mr-n-20 {
  margin-right: -20px; }

.m-n-25, .my-n-25, .mt-n-25 {
  margin-top: -25px; }

.m-n-25, .my-n-25, .mb-n-25 {
  margin-bottom: -25px; }

.m-n-25, .mx-n-25, .ml-n-25 {
  margin-left: -25px; }

.m-n-25, .mx-n-25, .mr-n-25 {
  margin-right: -25px; }

.m-n-30, .my-n-30, .mt-n-30 {
  margin-top: -30px; }

.m-n-30, .my-n-30, .mb-n-30 {
  margin-bottom: -30px; }

.m-n-30, .mx-n-30, .ml-n-30 {
  margin-left: -30px; }

.m-n-30, .mx-n-30, .mr-n-30 {
  margin-right: -30px; }

.p-5, .py-5, .pt-5 {
  padding-top: 5px; }

.p-5, .py-5, .pb-5 {
  padding-bottom: 5px; }

.p-5, .px-5, .pl-5 {
  padding-left: 5px; }

.p-5, .px-5, .pr-5 {
  padding-right: 5px; }

.p-10, .py-10, .pt-10 {
  padding-top: 10px; }

.p-10, .py-10, .pb-10 {
  padding-bottom: 10px; }

.p-10, .px-10, .pl-10 {
  padding-left: 10px; }

.p-10, .px-10, .pr-10 {
  padding-right: 10px; }

.p-15, .py-15, .pt-15 {
  padding-top: 15px; }

.p-15, .py-15, .pb-15 {
  padding-bottom: 15px; }

.p-15, .px-15, .pl-15 {
  padding-left: 15px; }

.p-15, .px-15, .pr-15 {
  padding-right: 15px; }

.p-20, .py-20, .pt-20 {
  padding-top: 20px; }

.p-20, .py-20, .pb-20 {
  padding-bottom: 20px; }

.p-20, .px-20, .pl-20 {
  padding-left: 20px; }

.p-20, .px-20, .pr-20 {
  padding-right: 20px; }

.p-25, .py-25, .pt-25 {
  padding-top: 25px; }

.p-25, .py-25, .pb-25 {
  padding-bottom: 25px; }

.p-25, .px-25, .pl-25 {
  padding-left: 25px; }

.p-25, .px-25, .pr-25 {
  padding-right: 25px; }

.p-30, .py-30, .pt-30 {
  padding-top: 30px; }

.p-30, .py-30, .pb-30 {
  padding-bottom: 30px; }

.p-30, .px-30, .pl-30 {
  padding-left: 30px; }

.p-30, .px-30, .pr-30 {
  padding-right: 30px; }

/* Customize tab */
p-tabPanel > .ui-tabview-panel {
  padding: 15px; }

p-tabPanel.no-padding > .ui-tabview-panel {
  padding: 0; }

.ps-form-container .__modal-tab-title {
  text-transform: uppercase;
  color: #0386d0;
  margin: 5px 0 15px; }

body .ui-menu .ui-menuitem .ui-menuitem-link .ui-menuitem-icon {
  margin-right: .6em; }

body .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter-icon {
  top: 10px;
  right: 15px;
  font-size: 14px; }

body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item {
  padding: 4px 10px; }

body .ui-inputtext {
  font-size: 12px;
  padding: 4px 8px;
  line-height: 14px; }

body .ui-dropdown-panel .ui-dropdown-filter-container {
  padding: 5px 10px; }

p-dropdown .ui-dropdown .ui-dropdown-label {
  padding-right: 24px; }

p-dropdown .ui-corner-all, p-dropdown .ui-inputtext {
  border-radius: 0; }

p-dropdown .ui-placeholder {
  color: #888; }

.ps-custom-dropdown-wrapper {
  position: relative;
  z-index: 10; }
  .ps-custom-dropdown-wrapper.inline {
    display: inline-block; }

.ps-custom-dropdown {
  position: absolute;
  width: 200px;
  z-index: 10;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3); }
  .ps-custom-dropdown.large-size {
    width: 250px; }
  .ps-custom-dropdown.top-left {
    bottom: 100%;
    right: 0; }
  .ps-custom-dropdown.top-right {
    bottom: 100%;
    left: 0; }
  .ps-custom-dropdown.bottom-left {
    top: 100%;
    right: 0; }
  .ps-custom-dropdown.bottom-right {
    top: 100%;
    left: 0; }
  .ps-custom-dropdown.top-left, .ps-custom-dropdown.top-right {
    transform: translateY(-5px); }
  .ps-custom-dropdown.bottom-left, .ps-custom-dropdown.bottom-right {
    transform: translateY(5px); }
  .ps-custom-dropdown.style-1 {
    padding: 10px; }
    .ps-custom-dropdown.style-1 .__action {
      padding: 5px 10px;
      color: #212529;
      display: block;
      -webkit-transition: background-color 0.3s ease-out;
      -o-transition: background-color 0.3s ease-out;
      transition: background-color 0.3s ease-out; }
      .ps-custom-dropdown.style-1 .__action:hover {
        background-color: rgba(46, 159, 224, 0.7); }

body .ui-multiselect {
  display: block;
  border-radius: 0; }

body .ui-multiselect .ui-multiselect-label {
  line-height: 14px;
  padding: 0 8px;
  padding-right: 2.3em; }

body .ui-multiselect-panel,
body .ui-multiselect .ui-multiselect-panel {
  min-width: 200px; }

body .ui-multiselect-panel .ui-multiselect-header {
  padding: 5px 20px 5px 10px; }

body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-multiselect-filter-icon {
  font-size: 14px;
  top: 5px;
  left: 5px; }

.ui-multiselect-panel .ui-multiselect-item .ui-chkbox {
  margin-right: 5px; }

body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item {
  padding: 6px 10px; }

body .ui-multiselect-label .ps-multi-select-tag {
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  padding: 2px 20px 2px 6px;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: #337ab7;
  color: #ffffff; }

body .ui-multiselect-label .ps-multi-select-tag {
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  padding: 2px 6px;
  margin: 2px 3px 2px 0;
  background-color: #337ab7;
  color: #ffffff; }

body > .ui-multiselect-panel {
  position: fixed;
  display: block !important; }

body .ui-multiselect-label .ps-multi-select-placeholder {
  padding: 2px 6px;
  margin-top: 2px;
  margin-bottom: 2px; }

mwl-text-input-autocomplete-menu .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 200px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }

mwl-text-input-autocomplete-menu .dropdown-menu > .active > a, mwl-text-input-autocomplete-menu .dropdown-menu > .active > a:focus, mwl-text-input-autocomplete-menu .dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0; }

mwl-text-input-autocomplete-menu .dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap; }

.ps-custom-overlay-panel-wrapper .ui-overlaypanel-content {
  padding: 10px; }

.ps-custom-overlay-panel .__action {
  padding: 5px 10px;
  color: #212529;
  display: block;
  -webkit-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }
  .ps-custom-overlay-panel .__action:hover {
    background-color: rgba(46, 159, 224, 0.7); }

.ps-dropdown-with-table ul.ui-dropdown-items, .ps-dropdown-with-table .ui-th, .ps-dropdown-with-table .ui-td {
  border: 1px solid #ddd; }

.ps-dropdown-with-table ul.ui-dropdown-items {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse; }

.ps-dropdown-with-table .ui-th, .ps-dropdown-with-table .ui-td {
  display: table-cell;
  padding: 6px; }

.ps-dropdown-with-table li.ui-dropdown-item-group, .ps-dropdown-with-table li.ui-dropdown-item {
  display: table-row; }

.ps-dropdown-with-table li.ui-dropdown-item-group {
  background: #eee; }

.ps-dropdown-with-table p-dropdownitem {
  display: table-row-group; }

.ps-dropdown-with-table .ui-dropdown-panel .ui-dropdown-filter-container {
  padding: 10px; }

.ps-dropdown-with-table .ui-dropdown-items-wrapper {
  padding: 5px 10px 10px; }

input[type="text"],
input[type="search"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="date"],
textarea {
  padding-left: 8px;
  padding-right: 8px;
  height: 24px;
  outline: none;
  font-size: 12px;
  border: 1px solid #ddd; }
  input[type="text"][readonly],
  input[type="search"][readonly],
  input[type="email"][readonly],
  input[type="url"][readonly],
  input[type="password"][readonly],
  input[type="tel"][readonly],
  input[type="number"][readonly],
  input[type="date"][readonly],
  textarea[readonly] {
    background: #eeeeee; }
  input[type="text"].full-width,
  input[type="search"].full-width,
  input[type="email"].full-width,
  input[type="url"].full-width,
  input[type="password"].full-width,
  input[type="tel"].full-width,
  input[type="number"].full-width,
  input[type="date"].full-width,
  textarea.full-width {
    width: 100%; }

input[type="number"] {
  padding-right: 0; }

textarea {
  padding: 6px 12px;
  height: auto;
  resize: vertical; }

body .ui-radiobutton-label:hover, body .ui-chkbox-label:hover {
  cursor: pointer; }

.ps-form-control .__form-control-label {
  background-color: #dddddd;
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .ps-form-control .__form-control-label.no-bgc {
    background-color: transparent; }

.ps-form-control .__form-control-inputs {
  display: flex;
  padding: 0; }
  .ps-form-control .__form-control-inputs > div {
    margin-right: 10px;
    flex: 1 1; }
  .ps-form-control .__form-control-inputs > div:last-of-type {
    margin-right: 0; }
  .ps-form-control .__form-control-inputs input[type="text"],
  .ps-form-control .__form-control-inputs input[type="search"],
  .ps-form-control .__form-control-inputs input[type="email"],
  .ps-form-control .__form-control-inputs input[type="url"],
  .ps-form-control .__form-control-inputs input[type="password"],
  .ps-form-control .__form-control-inputs input[type="number"],
  .ps-form-control .__form-control-inputs input[type="tel"],
  .ps-form-control .__form-control-inputs input[type="date"],
  .ps-form-control .__form-control-inputs textarea {
    width: 100%; }
  .ps-form-control .__form-control-inputs .ui-dropdown {
    min-width: 0; }
  .ps-form-control .__form-control-inputs .__radio-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around; }

.ps-form-container .ps-form-control {
  margin-bottom: 5px; }
  .ps-form-container .ps-form-control .__form-control-label {
    margin-bottom: 5px; }
  .ps-form-container .ps-form-control .__form-control-inputs {
    margin-bottom: 5px;
    align-items: center; }
    .ps-form-container .ps-form-control .__form-control-inputs .__input-wrapper.required {
      position: relative; }
      .ps-form-container .ps-form-control .__form-control-inputs .__input-wrapper.required:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        border: 6px solid #ff882e;
        width: 0;
        height: 0;
        border-bottom-color: transparent;
        border-left-color: transparent; }

.ps-form-container.default-control-label-width .ps-form-control {
  display: flex; }
  @media (min-width: 40.063em) {
    .ps-form-container.default-control-label-width .ps-form-control {
      flex-wrap: nowrap; }
      .ps-form-container.default-control-label-width .ps-form-control .__form-control-label {
        min-width: 160px;
        width: 160px; }
      .ps-form-container.default-control-label-width .ps-form-control .__form-control-inputs {
        flex-grow: 1; } }

.ps-custom-checkbox {
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer; }
  .ps-custom-checkbox input {
    display: none; }
  .ps-custom-checkbox .__mark {
    display: block;
    border-radius: 2px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    width: 16px;
    height: 16px;
    text-align: center;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color 0.3s, background-color 0.3s, box-shadow 0.3s; }
    .ps-custom-checkbox .__mark:before {
      content: "\e909";
      font-family: 'primeicons';
      font-size: 14px;
      line-height: 14px;
      display: block;
      opacity: 0; }
  .ps-custom-checkbox input:disabled + .__mark {
    cursor: default;
    background-color: #eeeeee;
    color: #777777; }
  .ps-custom-checkbox input:checked + .__mark {
    border: 1px solid #337ab7;
    background-color: #337ab7;
    color: #fff; }
    .ps-custom-checkbox input:checked + .__mark:before {
      opacity: 1; }

body .ui-chkbox .ui-chkbox-box {
  width: 16px;
  height: 16px; }

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
  font-size: 14px;
  line-height: 14px; }

body .ui-radiobutton .ui-radiobutton-box {
  width: 16px;
  height: 16px; }

.ps-input-wrapper.required {
  position: relative; }
  .ps-input-wrapper.required:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    border: 6px solid #ff882e;
    width: 0;
    height: 0;
    border-bottom-color: transparent;
    border-left-color: transparent; }

.ps-input.input-with-icon {
  position: relative; }
  .ps-input.input-with-icon .__input {
    padding-right: 30px; }
  .ps-input.input-with-icon .__icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center; }

.ui-radiobutton-icon {
  margin: 0; }

body .ui-radiobutton {
  vertical-align: top; }

body .ui-radiobutton .ui-radiobutton-box {
  display: flex;
  align-items: center;
  justify-content: center; }

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container {
  padding: 0 8px;
  border-radius: 0;
  flex: 1 1; }

body .ui-autocomplete .ui-autocomplete-dropdown {
  width: 24px;
  height: 24px;
  border-radius: 0; }

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-token {
  font-size: 12px;
  border-radius: 2px;
  padding: 2px 20px 2px 6px;
  margin-top: 2px;
  margin-bottom: 2px; }

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token {
  padding: 0;
  height: 18px;
  margin-top: 2px;
  margin-bottom: 2px; }

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token input {
  font-size: 12px;
  height: 18px;
  border: 0; }

p-autocomplete .ui-autocomplete {
  display: flex; }

p-autocomplete.ui-inputwrapper-filled .ui-autocomplete-input-token input {
  width: 70px; }

button:disabled {
  cursor: not-allowed; }

.ps-btn {
  outline: none;
  cursor: pointer;
  height: 24px;
  font-size: inherit; }
  .ps-btn .__icon {
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  .ps-btn.icon-only {
    border: 0;
    background-color: transparent;
    padding: 0;
    width: 24px; }
    .ps-btn.icon-only .__icon {
      display: block;
      font-size: 16px; }
  .ps-btn.easing {
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  .ps-btn.no-border {
    border: 0; }
  .ps-btn.default-border {
    border: 1px solid #ddd; }

td > .ps-table-row-actions {
  margin-top: -5px;
  margin-bottom: -5px; }

.ps-table-row-actions .ps-btn {
  margin: 1px; }

.ps-btn.ps-top-table-btn,
.ps-btn.ps-modal-btn {
  position: relative;
  padding-left: 26px;
  padding-right: 10px;
  border-radius: 2px; }
  .ps-btn.ps-top-table-btn.no-icon,
  .ps-btn.ps-modal-btn.no-icon {
    padding-left: 10px; }
  .ps-btn.ps-top-table-btn .__icon,
  .ps-btn.ps-modal-btn .__icon {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    font-size: 14px;
    width: 26px;
    padding-top: 4px; }
  .ps-btn.ps-top-table-btn.middle-icon .__icon,
  .ps-btn.ps-modal-btn.middle-icon .__icon {
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center; }

.ps-btn.ps-top-table-btn {
  background: transparent;
  border: 1px solid transparent;
  -webkit-transition: border-color 0.3s ease-out;
  -o-transition: border-color 0.3s ease-out;
  transition: border-color 0.3s ease-out; }
  .ps-btn.ps-top-table-btn.trigger-dropdown {
    padding-right: 20px; }
  .ps-btn.ps-top-table-btn .__icon {
    padding-top: 4px; }
  .ps-btn.ps-top-table-btn .__dropdown-hint {
    position: absolute;
    top: 0;
    height: 100%;
    color: #888;
    right: 0;
    width: 20px;
    padding-top: 6px; }
  .ps-btn.ps-top-table-btn.right-icon {
    padding-left: 10px;
    padding-right: 26px; }
    .ps-btn.ps-top-table-btn.right-icon .__icon {
      right: 0;
      left: auto; }
  .ps-btn.ps-top-table-btn:hover {
    border-color: #ddd; }

.ps-btn.ps-modal-btn {
  border: 0;
  color: #fff;
  -webkit-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.ps-modal-more-action-btn,
.ps-advance-actions-btn {
  border: 1px solid #aaa;
  background-color: #fff;
  border-radius: 2px;
  width: 24px; }

.ps-theme-blue-color,
.ps-theme-blue-hover-color.activated,
.ps-theme-blue-hover-color:hover {
  color: #337ab7; }

.ps-theme-blue-color-i,
.ps-theme-blue-hover-color-i.activated,
.ps-theme-blue-hover-color-i:hover {
  color: #337ab7 !important; }

.ps-theme-blue-color-lighter,
.ps-theme-blue-hover-color-lighter.activated,
.ps-theme-blue-hover-color-lighter:hover {
  color: #4f93ce; }

.ps-theme-blue-color-lighter-i,
.ps-theme-blue-hover-color-lighter-i.activated,
.ps-theme-blue-hover-color-lighter-i:hover {
  color: #4f93ce !important; }

.ps-theme-blue-color-bolder,
.ps-theme-blue-hover-color-bolder.activated,
.ps-theme-blue-hover-color-bolder:hover {
  color: #285f8f; }

.ps-theme-blue-color-bolder-i,
.ps-theme-blue-hover-color-bolder-i.activated,
.ps-theme-blue-hover-color-bolder-i:hover {
  color: #285f8f !important; }

.ps-theme-blue-bgc,
.ps-theme-blue-hover-bgc.activated,
.ps-theme-blue-hover-bgc:hover {
  background-color: #337ab7; }

.ps-theme-blue-bgc-i,
.ps-theme-blue-hover-bgc-i.activated,
.ps-theme-blue-hover-bgc-i:hover {
  background-color: #337ab7 !important; }

.ps-theme-blue-bgc-lighter,
.ps-theme-blue-hover-bgc-lighter.activated,
.ps-theme-blue-hover-bgc-lighter:hover {
  background-color: #4f93ce; }

.ps-theme-blue-bgc-lighter-i,
.ps-theme-blue-hover-bgc-lighter-i.activated,
.ps-theme-blue-hover-bgc-lighter-i:hover {
  background-color: #4f93ce !important; }

.ps-theme-blue-bgc-bolder,
.ps-theme-blue-hover-bgc-bolder.activated,
.ps-theme-blue-hover-bgc-bolder:hover {
  background-color: #285f8f; }

.ps-theme-blue-bgc-bolder-i,
.ps-theme-blue-hover-bgc-bolder-i.activated,
.ps-theme-blue-hover-bgc-bolder-i:hover {
  background-color: #285f8f !important; }

.ps-btn-action-theme-blue:not(.ps-modal-btn) .__icon {
  color: #337ab7; }

.ps-btn-action-theme-blue:not(.ps-modal-btn):hover .__icon {
  color: #285f8f; }

.ps-btn-action-theme-blue.ps-modal-btn {
  background-color: #337ab7; }
  .ps-btn-action-theme-blue.ps-modal-btn:hover {
    background-color: #285f8f; }

.ps-base-color,
.ps-base-hover-color.activated,
.ps-base-hover-color:hover {
  color: #1ca7ff; }

.ps-base-color-i,
.ps-base-hover-color-i.activated,
.ps-base-hover-color-i:hover {
  color: #1ca7ff !important; }

.ps-base-color-lighter,
.ps-base-hover-color-lighter.activated,
.ps-base-hover-color-lighter:hover {
  color: #4fbbff; }

.ps-base-color-lighter-i,
.ps-base-hover-color-lighter-i.activated,
.ps-base-hover-color-lighter-i:hover {
  color: #4fbbff !important; }

.ps-base-color-bolder,
.ps-base-hover-color-bolder.activated,
.ps-base-hover-color-bolder:hover {
  color: #008ee8; }

.ps-base-color-bolder-i,
.ps-base-hover-color-bolder-i.activated,
.ps-base-hover-color-bolder-i:hover {
  color: #008ee8 !important; }

.ps-base-bgc,
.ps-base-hover-bgc.activated,
.ps-base-hover-bgc:hover {
  background-color: #1ca7ff; }

.ps-base-bgc-i,
.ps-base-hover-bgc-i.activated,
.ps-base-hover-bgc-i:hover {
  background-color: #1ca7ff !important; }

.ps-base-bgc-lighter,
.ps-base-hover-bgc-lighter.activated,
.ps-base-hover-bgc-lighter:hover {
  background-color: #4fbbff; }

.ps-base-bgc-lighter-i,
.ps-base-hover-bgc-lighter-i.activated,
.ps-base-hover-bgc-lighter-i:hover {
  background-color: #4fbbff !important; }

.ps-base-bgc-bolder,
.ps-base-hover-bgc-bolder.activated,
.ps-base-hover-bgc-bolder:hover {
  background-color: #008ee8; }

.ps-base-bgc-bolder-i,
.ps-base-hover-bgc-bolder-i.activated,
.ps-base-hover-bgc-bolder-i:hover {
  background-color: #008ee8 !important; }

.ps-btn-action-base:not(.ps-modal-btn) .__icon {
  color: #1ca7ff; }

.ps-btn-action-base:not(.ps-modal-btn):hover .__icon {
  color: #008ee8; }

.ps-btn-action-base.ps-modal-btn {
  background-color: #1ca7ff; }
  .ps-btn-action-base.ps-modal-btn:hover {
    background-color: #008ee8; }

.ps-info-color,
.ps-info-hover-color.activated,
.ps-info-hover-color:hover {
  color: #2e9fe0; }

.ps-info-color-i,
.ps-info-hover-color-i.activated,
.ps-info-hover-color-i:hover {
  color: #2e9fe0 !important; }

.ps-info-color-lighter,
.ps-info-hover-color-lighter.activated,
.ps-info-hover-color-lighter:hover {
  color: #5ab3e7; }

.ps-info-color-lighter-i,
.ps-info-hover-color-lighter-i.activated,
.ps-info-hover-color-lighter-i:hover {
  color: #5ab3e7 !important; }

.ps-info-color-bolder,
.ps-info-hover-color-bolder.activated,
.ps-info-hover-color-bolder:hover {
  color: #1c83bf; }

.ps-info-color-bolder-i,
.ps-info-hover-color-bolder-i.activated,
.ps-info-hover-color-bolder-i:hover {
  color: #1c83bf !important; }

.ps-info-bgc,
.ps-info-hover-bgc.activated,
.ps-info-hover-bgc:hover {
  background-color: #2e9fe0; }

.ps-info-bgc-i,
.ps-info-hover-bgc-i.activated,
.ps-info-hover-bgc-i:hover {
  background-color: #2e9fe0 !important; }

.ps-info-bgc-lighter,
.ps-info-hover-bgc-lighter.activated,
.ps-info-hover-bgc-lighter:hover {
  background-color: #5ab3e7; }

.ps-info-bgc-lighter-i,
.ps-info-hover-bgc-lighter-i.activated,
.ps-info-hover-bgc-lighter-i:hover {
  background-color: #5ab3e7 !important; }

.ps-info-bgc-bolder,
.ps-info-hover-bgc-bolder.activated,
.ps-info-hover-bgc-bolder:hover {
  background-color: #1c83bf; }

.ps-info-bgc-bolder-i,
.ps-info-hover-bgc-bolder-i.activated,
.ps-info-hover-bgc-bolder-i:hover {
  background-color: #1c83bf !important; }

.ps-btn-action-info:not(.ps-modal-btn) .__icon {
  color: #2e9fe0; }

.ps-btn-action-info:not(.ps-modal-btn):hover .__icon {
  color: #1c83bf; }

.ps-btn-action-info.ps-modal-btn {
  background-color: #2e9fe0; }
  .ps-btn-action-info.ps-modal-btn:hover {
    background-color: #1c83bf; }

.ps-danger-color,
.ps-danger-hover-color.activated,
.ps-danger-hover-color:hover {
  color: #b82020; }

.ps-danger-color-i,
.ps-danger-hover-color-i.activated,
.ps-danger-hover-color-i:hover {
  color: #b82020 !important; }

.ps-danger-color-lighter,
.ps-danger-hover-color-lighter.activated,
.ps-danger-hover-color-lighter:hover {
  color: #db3030; }

.ps-danger-color-lighter-i,
.ps-danger-hover-color-lighter-i.activated,
.ps-danger-hover-color-lighter-i:hover {
  color: #db3030 !important; }

.ps-danger-color-bolder,
.ps-danger-hover-color-bolder.activated,
.ps-danger-hover-color-bolder:hover {
  color: #8d1818; }

.ps-danger-color-bolder-i,
.ps-danger-hover-color-bolder-i.activated,
.ps-danger-hover-color-bolder-i:hover {
  color: #8d1818 !important; }

.ps-danger-bgc,
.ps-danger-hover-bgc.activated,
.ps-danger-hover-bgc:hover {
  background-color: #b82020; }

.ps-danger-bgc-i,
.ps-danger-hover-bgc-i.activated,
.ps-danger-hover-bgc-i:hover {
  background-color: #b82020 !important; }

.ps-danger-bgc-lighter,
.ps-danger-hover-bgc-lighter.activated,
.ps-danger-hover-bgc-lighter:hover {
  background-color: #db3030; }

.ps-danger-bgc-lighter-i,
.ps-danger-hover-bgc-lighter-i.activated,
.ps-danger-hover-bgc-lighter-i:hover {
  background-color: #db3030 !important; }

.ps-danger-bgc-bolder,
.ps-danger-hover-bgc-bolder.activated,
.ps-danger-hover-bgc-bolder:hover {
  background-color: #8d1818; }

.ps-danger-bgc-bolder-i,
.ps-danger-hover-bgc-bolder-i.activated,
.ps-danger-hover-bgc-bolder-i:hover {
  background-color: #8d1818 !important; }

.ps-btn-action-danger:not(.ps-modal-btn) .__icon {
  color: #b82020; }

.ps-btn-action-danger:not(.ps-modal-btn):hover .__icon {
  color: #8d1818; }

.ps-btn-action-danger.ps-modal-btn {
  background-color: #b82020; }
  .ps-btn-action-danger.ps-modal-btn:hover {
    background-color: #8d1818; }

.ps-edit-color,
.ps-edit-hover-color.activated,
.ps-edit-hover-color:hover {
  color: #0386d0; }

.ps-edit-color-i,
.ps-edit-hover-color-i.activated,
.ps-edit-hover-color-i:hover {
  color: #0386d0 !important; }

.ps-edit-color-lighter,
.ps-edit-hover-color-lighter.activated,
.ps-edit-hover-color-lighter:hover {
  color: #0ba4fb; }

.ps-edit-color-lighter-i,
.ps-edit-hover-color-lighter-i.activated,
.ps-edit-hover-color-lighter-i:hover {
  color: #0ba4fb !important; }

.ps-edit-color-bolder,
.ps-edit-hover-color-bolder.activated,
.ps-edit-hover-color-bolder:hover {
  color: #02669e; }

.ps-edit-color-bolder-i,
.ps-edit-hover-color-bolder-i.activated,
.ps-edit-hover-color-bolder-i:hover {
  color: #02669e !important; }

.ps-edit-bgc,
.ps-edit-hover-bgc.activated,
.ps-edit-hover-bgc:hover {
  background-color: #0386d0; }

.ps-edit-bgc-i,
.ps-edit-hover-bgc-i.activated,
.ps-edit-hover-bgc-i:hover {
  background-color: #0386d0 !important; }

.ps-edit-bgc-lighter,
.ps-edit-hover-bgc-lighter.activated,
.ps-edit-hover-bgc-lighter:hover {
  background-color: #0ba4fb; }

.ps-edit-bgc-lighter-i,
.ps-edit-hover-bgc-lighter-i.activated,
.ps-edit-hover-bgc-lighter-i:hover {
  background-color: #0ba4fb !important; }

.ps-edit-bgc-bolder,
.ps-edit-hover-bgc-bolder.activated,
.ps-edit-hover-bgc-bolder:hover {
  background-color: #02669e; }

.ps-edit-bgc-bolder-i,
.ps-edit-hover-bgc-bolder-i.activated,
.ps-edit-hover-bgc-bolder-i:hover {
  background-color: #02669e !important; }

.ps-btn-action-edit:not(.ps-modal-btn) .__icon {
  color: #0386d0; }

.ps-btn-action-edit:not(.ps-modal-btn):hover .__icon {
  color: #02669e; }

.ps-btn-action-edit.ps-modal-btn {
  background-color: #0386d0; }
  .ps-btn-action-edit.ps-modal-btn:hover {
    background-color: #02669e; }

.ps-remove-color,
.ps-remove-hover-color.activated,
.ps-remove-hover-color:hover {
  color: #f44c4c; }

.ps-remove-color-i,
.ps-remove-hover-color-i.activated,
.ps-remove-hover-color-i:hover {
  color: #f44c4c !important; }

.ps-remove-color-lighter,
.ps-remove-hover-color-lighter.activated,
.ps-remove-hover-color-lighter:hover {
  color: #f77c7c; }

.ps-remove-color-lighter-i,
.ps-remove-hover-color-lighter-i.activated,
.ps-remove-hover-color-lighter-i:hover {
  color: #f77c7c !important; }

.ps-remove-color-bolder,
.ps-remove-hover-color-bolder.activated,
.ps-remove-hover-color-bolder:hover {
  color: #f11c1c; }

.ps-remove-color-bolder-i,
.ps-remove-hover-color-bolder-i.activated,
.ps-remove-hover-color-bolder-i:hover {
  color: #f11c1c !important; }

.ps-remove-bgc,
.ps-remove-hover-bgc.activated,
.ps-remove-hover-bgc:hover {
  background-color: #f44c4c; }

.ps-remove-bgc-i,
.ps-remove-hover-bgc-i.activated,
.ps-remove-hover-bgc-i:hover {
  background-color: #f44c4c !important; }

.ps-remove-bgc-lighter,
.ps-remove-hover-bgc-lighter.activated,
.ps-remove-hover-bgc-lighter:hover {
  background-color: #f77c7c; }

.ps-remove-bgc-lighter-i,
.ps-remove-hover-bgc-lighter-i.activated,
.ps-remove-hover-bgc-lighter-i:hover {
  background-color: #f77c7c !important; }

.ps-remove-bgc-bolder,
.ps-remove-hover-bgc-bolder.activated,
.ps-remove-hover-bgc-bolder:hover {
  background-color: #f11c1c; }

.ps-remove-bgc-bolder-i,
.ps-remove-hover-bgc-bolder-i.activated,
.ps-remove-hover-bgc-bolder-i:hover {
  background-color: #f11c1c !important; }

.ps-btn-action-remove:not(.ps-modal-btn) .__icon {
  color: #f44c4c; }

.ps-btn-action-remove:not(.ps-modal-btn):hover .__icon {
  color: #f11c1c; }

.ps-btn-action-remove.ps-modal-btn {
  background-color: #f44c4c; }
  .ps-btn-action-remove.ps-modal-btn:hover {
    background-color: #f11c1c; }

.ps-restore-color,
.ps-restore-hover-color.activated,
.ps-restore-hover-color:hover {
  color: #fda738; }

.ps-restore-color-i,
.ps-restore-hover-color-i.activated,
.ps-restore-hover-color-i:hover {
  color: #fda738 !important; }

.ps-restore-color-lighter,
.ps-restore-hover-color-lighter.activated,
.ps-restore-hover-color-lighter:hover {
  color: #febd6a; }

.ps-restore-color-lighter-i,
.ps-restore-hover-color-lighter-i.activated,
.ps-restore-hover-color-lighter-i:hover {
  color: #febd6a !important; }

.ps-restore-color-bolder,
.ps-restore-hover-color-bolder.activated,
.ps-restore-hover-color-bolder:hover {
  color: #fc9106; }

.ps-restore-color-bolder-i,
.ps-restore-hover-color-bolder-i.activated,
.ps-restore-hover-color-bolder-i:hover {
  color: #fc9106 !important; }

.ps-restore-bgc,
.ps-restore-hover-bgc.activated,
.ps-restore-hover-bgc:hover {
  background-color: #fda738; }

.ps-restore-bgc-i,
.ps-restore-hover-bgc-i.activated,
.ps-restore-hover-bgc-i:hover {
  background-color: #fda738 !important; }

.ps-restore-bgc-lighter,
.ps-restore-hover-bgc-lighter.activated,
.ps-restore-hover-bgc-lighter:hover {
  background-color: #febd6a; }

.ps-restore-bgc-lighter-i,
.ps-restore-hover-bgc-lighter-i.activated,
.ps-restore-hover-bgc-lighter-i:hover {
  background-color: #febd6a !important; }

.ps-restore-bgc-bolder,
.ps-restore-hover-bgc-bolder.activated,
.ps-restore-hover-bgc-bolder:hover {
  background-color: #fc9106; }

.ps-restore-bgc-bolder-i,
.ps-restore-hover-bgc-bolder-i.activated,
.ps-restore-hover-bgc-bolder-i:hover {
  background-color: #fc9106 !important; }

.ps-btn-action-restore:not(.ps-modal-btn) .__icon {
  color: #fda738; }

.ps-btn-action-restore:not(.ps-modal-btn):hover .__icon {
  color: #fc9106; }

.ps-btn-action-restore.ps-modal-btn {
  background-color: #fda738; }
  .ps-btn-action-restore.ps-modal-btn:hover {
    background-color: #fc9106; }

.ps-back-color,
.ps-back-hover-color.activated,
.ps-back-hover-color:hover {
  color: #999; }

.ps-back-color-i,
.ps-back-hover-color-i.activated,
.ps-back-hover-color-i:hover {
  color: #999 !important; }

.ps-back-color-lighter,
.ps-back-hover-color-lighter.activated,
.ps-back-hover-color-lighter:hover {
  color: #b3b2b2; }

.ps-back-color-lighter-i,
.ps-back-hover-color-lighter-i.activated,
.ps-back-hover-color-lighter-i:hover {
  color: #b3b2b2 !important; }

.ps-back-color-bolder,
.ps-back-hover-color-bolder.activated,
.ps-back-hover-color-bolder:hover {
  color: #807f7f; }

.ps-back-color-bolder-i,
.ps-back-hover-color-bolder-i.activated,
.ps-back-hover-color-bolder-i:hover {
  color: #807f7f !important; }

.ps-back-bgc,
.ps-back-hover-bgc.activated,
.ps-back-hover-bgc:hover {
  background-color: #999; }

.ps-back-bgc-i,
.ps-back-hover-bgc-i.activated,
.ps-back-hover-bgc-i:hover {
  background-color: #999 !important; }

.ps-back-bgc-lighter,
.ps-back-hover-bgc-lighter.activated,
.ps-back-hover-bgc-lighter:hover {
  background-color: #b3b2b2; }

.ps-back-bgc-lighter-i,
.ps-back-hover-bgc-lighter-i.activated,
.ps-back-hover-bgc-lighter-i:hover {
  background-color: #b3b2b2 !important; }

.ps-back-bgc-bolder,
.ps-back-hover-bgc-bolder.activated,
.ps-back-hover-bgc-bolder:hover {
  background-color: #807f7f; }

.ps-back-bgc-bolder-i,
.ps-back-hover-bgc-bolder-i.activated,
.ps-back-hover-bgc-bolder-i:hover {
  background-color: #807f7f !important; }

.ps-btn-action-back:not(.ps-modal-btn) .__icon {
  color: #999; }

.ps-btn-action-back:not(.ps-modal-btn):hover .__icon {
  color: #807f7f; }

.ps-btn-action-back.ps-modal-btn {
  background-color: #999; }
  .ps-btn-action-back.ps-modal-btn:hover {
    background-color: #807f7f; }

.ps-lock-color,
.ps-lock-hover-color.activated,
.ps-lock-hover-color:hover {
  color: #b37d1f; }

.ps-lock-color-i,
.ps-lock-hover-color-i.activated,
.ps-lock-hover-color-i:hover {
  color: #b37d1f !important; }

.ps-lock-color-lighter,
.ps-lock-hover-color-lighter.activated,
.ps-lock-hover-color-lighter:hover {
  color: #da9a2b; }

.ps-lock-color-lighter-i,
.ps-lock-hover-color-lighter-i.activated,
.ps-lock-hover-color-lighter-i:hover {
  color: #da9a2b !important; }

.ps-lock-color-bolder,
.ps-lock-hover-color-bolder.activated,
.ps-lock-hover-color-bolder:hover {
  color: #885f17; }

.ps-lock-color-bolder-i,
.ps-lock-hover-color-bolder-i.activated,
.ps-lock-hover-color-bolder-i:hover {
  color: #885f17 !important; }

.ps-lock-bgc,
.ps-lock-hover-bgc.activated,
.ps-lock-hover-bgc:hover {
  background-color: #b37d1f; }

.ps-lock-bgc-i,
.ps-lock-hover-bgc-i.activated,
.ps-lock-hover-bgc-i:hover {
  background-color: #b37d1f !important; }

.ps-lock-bgc-lighter,
.ps-lock-hover-bgc-lighter.activated,
.ps-lock-hover-bgc-lighter:hover {
  background-color: #da9a2b; }

.ps-lock-bgc-lighter-i,
.ps-lock-hover-bgc-lighter-i.activated,
.ps-lock-hover-bgc-lighter-i:hover {
  background-color: #da9a2b !important; }

.ps-lock-bgc-bolder,
.ps-lock-hover-bgc-bolder.activated,
.ps-lock-hover-bgc-bolder:hover {
  background-color: #885f17; }

.ps-lock-bgc-bolder-i,
.ps-lock-hover-bgc-bolder-i.activated,
.ps-lock-hover-bgc-bolder-i:hover {
  background-color: #885f17 !important; }

.ps-btn-action-lock:not(.ps-modal-btn) .__icon {
  color: #b37d1f; }

.ps-btn-action-lock:not(.ps-modal-btn):hover .__icon {
  color: #885f17; }

.ps-btn-action-lock.ps-modal-btn {
  background-color: #b37d1f; }
  .ps-btn-action-lock.ps-modal-btn:hover {
    background-color: #885f17; }

.ps-unlock-color,
.ps-unlock-hover-color.activated,
.ps-unlock-hover-color:hover {
  color: #2552e7; }

.ps-unlock-color-i,
.ps-unlock-hover-color-i.activated,
.ps-unlock-hover-color-i:hover {
  color: #2552e7 !important; }

.ps-unlock-color-lighter,
.ps-unlock-hover-color-lighter.activated,
.ps-unlock-hover-color-lighter:hover {
  color: #5376ec; }

.ps-unlock-color-lighter-i,
.ps-unlock-hover-color-lighter-i.activated,
.ps-unlock-hover-color-lighter-i:hover {
  color: #5376ec !important; }

.ps-unlock-color-bolder,
.ps-unlock-hover-color-bolder.activated,
.ps-unlock-hover-color-bolder:hover {
  color: #163ec3; }

.ps-unlock-color-bolder-i,
.ps-unlock-hover-color-bolder-i.activated,
.ps-unlock-hover-color-bolder-i:hover {
  color: #163ec3 !important; }

.ps-unlock-bgc,
.ps-unlock-hover-bgc.activated,
.ps-unlock-hover-bgc:hover {
  background-color: #2552e7; }

.ps-unlock-bgc-i,
.ps-unlock-hover-bgc-i.activated,
.ps-unlock-hover-bgc-i:hover {
  background-color: #2552e7 !important; }

.ps-unlock-bgc-lighter,
.ps-unlock-hover-bgc-lighter.activated,
.ps-unlock-hover-bgc-lighter:hover {
  background-color: #5376ec; }

.ps-unlock-bgc-lighter-i,
.ps-unlock-hover-bgc-lighter-i.activated,
.ps-unlock-hover-bgc-lighter-i:hover {
  background-color: #5376ec !important; }

.ps-unlock-bgc-bolder,
.ps-unlock-hover-bgc-bolder.activated,
.ps-unlock-hover-bgc-bolder:hover {
  background-color: #163ec3; }

.ps-unlock-bgc-bolder-i,
.ps-unlock-hover-bgc-bolder-i.activated,
.ps-unlock-hover-bgc-bolder-i:hover {
  background-color: #163ec3 !important; }

.ps-btn-action-unlock:not(.ps-modal-btn) .__icon {
  color: #2552e7; }

.ps-btn-action-unlock:not(.ps-modal-btn):hover .__icon {
  color: #163ec3; }

.ps-btn-action-unlock.ps-modal-btn {
  background-color: #2552e7; }
  .ps-btn-action-unlock.ps-modal-btn:hover {
    background-color: #163ec3; }

.ps-print-color,
.ps-print-hover-color.activated,
.ps-print-hover-color:hover {
  color: #2552e7; }

.ps-print-color-i,
.ps-print-hover-color-i.activated,
.ps-print-hover-color-i:hover {
  color: #2552e7 !important; }

.ps-print-color-lighter,
.ps-print-hover-color-lighter.activated,
.ps-print-hover-color-lighter:hover {
  color: #5376ec; }

.ps-print-color-lighter-i,
.ps-print-hover-color-lighter-i.activated,
.ps-print-hover-color-lighter-i:hover {
  color: #5376ec !important; }

.ps-print-color-bolder,
.ps-print-hover-color-bolder.activated,
.ps-print-hover-color-bolder:hover {
  color: #163ec3; }

.ps-print-color-bolder-i,
.ps-print-hover-color-bolder-i.activated,
.ps-print-hover-color-bolder-i:hover {
  color: #163ec3 !important; }

.ps-print-bgc,
.ps-print-hover-bgc.activated,
.ps-print-hover-bgc:hover {
  background-color: #2552e7; }

.ps-print-bgc-i,
.ps-print-hover-bgc-i.activated,
.ps-print-hover-bgc-i:hover {
  background-color: #2552e7 !important; }

.ps-print-bgc-lighter,
.ps-print-hover-bgc-lighter.activated,
.ps-print-hover-bgc-lighter:hover {
  background-color: #5376ec; }

.ps-print-bgc-lighter-i,
.ps-print-hover-bgc-lighter-i.activated,
.ps-print-hover-bgc-lighter-i:hover {
  background-color: #5376ec !important; }

.ps-print-bgc-bolder,
.ps-print-hover-bgc-bolder.activated,
.ps-print-hover-bgc-bolder:hover {
  background-color: #163ec3; }

.ps-print-bgc-bolder-i,
.ps-print-hover-bgc-bolder-i.activated,
.ps-print-hover-bgc-bolder-i:hover {
  background-color: #163ec3 !important; }

.ps-btn-action-print:not(.ps-modal-btn) .__icon {
  color: #2552e7; }

.ps-btn-action-print:not(.ps-modal-btn):hover .__icon {
  color: #163ec3; }

.ps-btn-action-print.ps-modal-btn {
  background-color: #2552e7; }
  .ps-btn-action-print.ps-modal-btn:hover {
    background-color: #163ec3; }

.ps-config-color,
.ps-config-hover-color.activated,
.ps-config-hover-color:hover {
  color: #666; }

.ps-config-color-i,
.ps-config-hover-color-i.activated,
.ps-config-hover-color-i:hover {
  color: #666 !important; }

.ps-config-color-lighter,
.ps-config-hover-color-lighter.activated,
.ps-config-hover-color-lighter:hover {
  color: #807f7f; }

.ps-config-color-lighter-i,
.ps-config-hover-color-lighter-i.activated,
.ps-config-hover-color-lighter-i:hover {
  color: #807f7f !important; }

.ps-config-color-bolder,
.ps-config-hover-color-bolder.activated,
.ps-config-hover-color-bolder:hover {
  color: #4d4c4c; }

.ps-config-color-bolder-i,
.ps-config-hover-color-bolder-i.activated,
.ps-config-hover-color-bolder-i:hover {
  color: #4d4c4c !important; }

.ps-config-bgc,
.ps-config-hover-bgc.activated,
.ps-config-hover-bgc:hover {
  background-color: #666; }

.ps-config-bgc-i,
.ps-config-hover-bgc-i.activated,
.ps-config-hover-bgc-i:hover {
  background-color: #666 !important; }

.ps-config-bgc-lighter,
.ps-config-hover-bgc-lighter.activated,
.ps-config-hover-bgc-lighter:hover {
  background-color: #807f7f; }

.ps-config-bgc-lighter-i,
.ps-config-hover-bgc-lighter-i.activated,
.ps-config-hover-bgc-lighter-i:hover {
  background-color: #807f7f !important; }

.ps-config-bgc-bolder,
.ps-config-hover-bgc-bolder.activated,
.ps-config-hover-bgc-bolder:hover {
  background-color: #4d4c4c; }

.ps-config-bgc-bolder-i,
.ps-config-hover-bgc-bolder-i.activated,
.ps-config-hover-bgc-bolder-i:hover {
  background-color: #4d4c4c !important; }

.ps-btn-action-config:not(.ps-modal-btn) .__icon {
  color: #666; }

.ps-btn-action-config:not(.ps-modal-btn):hover .__icon {
  color: #4d4c4c; }

.ps-btn-action-config.ps-modal-btn {
  background-color: #666; }
  .ps-btn-action-config.ps-modal-btn:hover {
    background-color: #4d4c4c; }

.ps-report-color,
.ps-report-hover-color.activated,
.ps-report-hover-color:hover {
  color: #7c8fce; }

.ps-report-color-i,
.ps-report-hover-color-i.activated,
.ps-report-hover-color-i:hover {
  color: #7c8fce !important; }

.ps-report-color-lighter,
.ps-report-hover-color-lighter.activated,
.ps-report-hover-color-lighter:hover {
  color: #a1afdc; }

.ps-report-color-lighter-i,
.ps-report-hover-color-lighter-i.activated,
.ps-report-hover-color-lighter-i:hover {
  color: #a1afdc !important; }

.ps-report-color-bolder,
.ps-report-hover-color-bolder.activated,
.ps-report-hover-color-bolder:hover {
  color: #576fc0; }

.ps-report-color-bolder-i,
.ps-report-hover-color-bolder-i.activated,
.ps-report-hover-color-bolder-i:hover {
  color: #576fc0 !important; }

.ps-report-bgc,
.ps-report-hover-bgc.activated,
.ps-report-hover-bgc:hover {
  background-color: #7c8fce; }

.ps-report-bgc-i,
.ps-report-hover-bgc-i.activated,
.ps-report-hover-bgc-i:hover {
  background-color: #7c8fce !important; }

.ps-report-bgc-lighter,
.ps-report-hover-bgc-lighter.activated,
.ps-report-hover-bgc-lighter:hover {
  background-color: #a1afdc; }

.ps-report-bgc-lighter-i,
.ps-report-hover-bgc-lighter-i.activated,
.ps-report-hover-bgc-lighter-i:hover {
  background-color: #a1afdc !important; }

.ps-report-bgc-bolder,
.ps-report-hover-bgc-bolder.activated,
.ps-report-hover-bgc-bolder:hover {
  background-color: #576fc0; }

.ps-report-bgc-bolder-i,
.ps-report-hover-bgc-bolder-i.activated,
.ps-report-hover-bgc-bolder-i:hover {
  background-color: #576fc0 !important; }

.ps-btn-action-report:not(.ps-modal-btn) .__icon {
  color: #7c8fce; }

.ps-btn-action-report:not(.ps-modal-btn):hover .__icon {
  color: #576fc0; }

.ps-btn-action-report.ps-modal-btn {
  background-color: #7c8fce; }
  .ps-btn-action-report.ps-modal-btn:hover {
    background-color: #576fc0; }

.ps-validate-color,
.ps-validate-hover-color.activated,
.ps-validate-hover-color:hover {
  color: #14b545; }

.ps-validate-color-i,
.ps-validate-hover-color-i.activated,
.ps-validate-hover-color-i:hover {
  color: #14b545 !important; }

.ps-validate-color-lighter,
.ps-validate-hover-color-lighter.activated,
.ps-validate-hover-color-lighter:hover {
  color: #19e357; }

.ps-validate-color-lighter-i,
.ps-validate-hover-color-lighter-i.activated,
.ps-validate-hover-color-lighter-i:hover {
  color: #19e357 !important; }

.ps-validate-color-bolder,
.ps-validate-hover-color-bolder.activated,
.ps-validate-hover-color-bolder:hover {
  color: #0f8733; }

.ps-validate-color-bolder-i,
.ps-validate-hover-color-bolder-i.activated,
.ps-validate-hover-color-bolder-i:hover {
  color: #0f8733 !important; }

.ps-validate-bgc,
.ps-validate-hover-bgc.activated,
.ps-validate-hover-bgc:hover {
  background-color: #14b545; }

.ps-validate-bgc-i,
.ps-validate-hover-bgc-i.activated,
.ps-validate-hover-bgc-i:hover {
  background-color: #14b545 !important; }

.ps-validate-bgc-lighter,
.ps-validate-hover-bgc-lighter.activated,
.ps-validate-hover-bgc-lighter:hover {
  background-color: #19e357; }

.ps-validate-bgc-lighter-i,
.ps-validate-hover-bgc-lighter-i.activated,
.ps-validate-hover-bgc-lighter-i:hover {
  background-color: #19e357 !important; }

.ps-validate-bgc-bolder,
.ps-validate-hover-bgc-bolder.activated,
.ps-validate-hover-bgc-bolder:hover {
  background-color: #0f8733; }

.ps-validate-bgc-bolder-i,
.ps-validate-hover-bgc-bolder-i.activated,
.ps-validate-hover-bgc-bolder-i:hover {
  background-color: #0f8733 !important; }

.ps-btn-action-validate:not(.ps-modal-btn) .__icon {
  color: #14b545; }

.ps-btn-action-validate:not(.ps-modal-btn):hover .__icon {
  color: #0f8733; }

.ps-btn-action-validate.ps-modal-btn {
  background-color: #14b545; }
  .ps-btn-action-validate.ps-modal-btn:hover {
    background-color: #0f8733; }

.ps-view-color,
.ps-view-hover-color.activated,
.ps-view-hover-color:hover {
  color: #14b545; }

.ps-view-color-i,
.ps-view-hover-color-i.activated,
.ps-view-hover-color-i:hover {
  color: #14b545 !important; }

.ps-view-color-lighter,
.ps-view-hover-color-lighter.activated,
.ps-view-hover-color-lighter:hover {
  color: #19e357; }

.ps-view-color-lighter-i,
.ps-view-hover-color-lighter-i.activated,
.ps-view-hover-color-lighter-i:hover {
  color: #19e357 !important; }

.ps-view-color-bolder,
.ps-view-hover-color-bolder.activated,
.ps-view-hover-color-bolder:hover {
  color: #0f8733; }

.ps-view-color-bolder-i,
.ps-view-hover-color-bolder-i.activated,
.ps-view-hover-color-bolder-i:hover {
  color: #0f8733 !important; }

.ps-view-bgc,
.ps-view-hover-bgc.activated,
.ps-view-hover-bgc:hover {
  background-color: #14b545; }

.ps-view-bgc-i,
.ps-view-hover-bgc-i.activated,
.ps-view-hover-bgc-i:hover {
  background-color: #14b545 !important; }

.ps-view-bgc-lighter,
.ps-view-hover-bgc-lighter.activated,
.ps-view-hover-bgc-lighter:hover {
  background-color: #19e357; }

.ps-view-bgc-lighter-i,
.ps-view-hover-bgc-lighter-i.activated,
.ps-view-hover-bgc-lighter-i:hover {
  background-color: #19e357 !important; }

.ps-view-bgc-bolder,
.ps-view-hover-bgc-bolder.activated,
.ps-view-hover-bgc-bolder:hover {
  background-color: #0f8733; }

.ps-view-bgc-bolder-i,
.ps-view-hover-bgc-bolder-i.activated,
.ps-view-hover-bgc-bolder-i:hover {
  background-color: #0f8733 !important; }

.ps-btn-action-view:not(.ps-modal-btn) .__icon {
  color: #14b545; }

.ps-btn-action-view:not(.ps-modal-btn):hover .__icon {
  color: #0f8733; }

.ps-btn-action-view.ps-modal-btn {
  background-color: #14b545; }
  .ps-btn-action-view.ps-modal-btn:hover {
    background-color: #0f8733; }

.ps-data-color,
.ps-data-hover-color.activated,
.ps-data-hover-color:hover {
  color: #1c752d; }

.ps-data-color-i,
.ps-data-hover-color-i.activated,
.ps-data-hover-color-i:hover {
  color: #1c752d !important; }

.ps-data-color-lighter,
.ps-data-hover-color-lighter.activated,
.ps-data-hover-color-lighter:hover {
  color: #269e3d; }

.ps-data-color-lighter-i,
.ps-data-hover-color-lighter-i.activated,
.ps-data-hover-color-lighter-i:hover {
  color: #269e3d !important; }

.ps-data-color-bolder,
.ps-data-hover-color-bolder.activated,
.ps-data-hover-color-bolder:hover {
  color: #124c1d; }

.ps-data-color-bolder-i,
.ps-data-hover-color-bolder-i.activated,
.ps-data-hover-color-bolder-i:hover {
  color: #124c1d !important; }

.ps-data-bgc,
.ps-data-hover-bgc.activated,
.ps-data-hover-bgc:hover {
  background-color: #1c752d; }

.ps-data-bgc-i,
.ps-data-hover-bgc-i.activated,
.ps-data-hover-bgc-i:hover {
  background-color: #1c752d !important; }

.ps-data-bgc-lighter,
.ps-data-hover-bgc-lighter.activated,
.ps-data-hover-bgc-lighter:hover {
  background-color: #269e3d; }

.ps-data-bgc-lighter-i,
.ps-data-hover-bgc-lighter-i.activated,
.ps-data-hover-bgc-lighter-i:hover {
  background-color: #269e3d !important; }

.ps-data-bgc-bolder,
.ps-data-hover-bgc-bolder.activated,
.ps-data-hover-bgc-bolder:hover {
  background-color: #124c1d; }

.ps-data-bgc-bolder-i,
.ps-data-hover-bgc-bolder-i.activated,
.ps-data-hover-bgc-bolder-i:hover {
  background-color: #124c1d !important; }

.ps-btn-action-data:not(.ps-modal-btn) .__icon {
  color: #1c752d; }

.ps-btn-action-data:not(.ps-modal-btn):hover .__icon {
  color: #124c1d; }

.ps-btn-action-data.ps-modal-btn {
  background-color: #1c752d; }
  .ps-btn-action-data.ps-modal-btn:hover {
    background-color: #124c1d; }

.ps-light-color,
.ps-light-hover-color.activated,
.ps-light-hover-color:hover {
  color: #fff; }

.ps-light-color-i,
.ps-light-hover-color-i.activated,
.ps-light-hover-color-i:hover {
  color: #fff !important; }

.ps-light-color-lighter,
.ps-light-hover-color-lighter.activated,
.ps-light-hover-color-lighter:hover {
  color: white; }

.ps-light-color-lighter-i,
.ps-light-hover-color-lighter-i.activated,
.ps-light-hover-color-lighter-i:hover {
  color: white !important; }

.ps-light-color-bolder,
.ps-light-hover-color-bolder.activated,
.ps-light-hover-color-bolder:hover {
  color: #e6e5e5; }

.ps-light-color-bolder-i,
.ps-light-hover-color-bolder-i.activated,
.ps-light-hover-color-bolder-i:hover {
  color: #e6e5e5 !important; }

.ps-light-bgc,
.ps-light-hover-bgc.activated,
.ps-light-hover-bgc:hover {
  background-color: #fff; }

.ps-light-bgc-i,
.ps-light-hover-bgc-i.activated,
.ps-light-hover-bgc-i:hover {
  background-color: #fff !important; }

.ps-light-bgc-lighter,
.ps-light-hover-bgc-lighter.activated,
.ps-light-hover-bgc-lighter:hover {
  background-color: white; }

.ps-light-bgc-lighter-i,
.ps-light-hover-bgc-lighter-i.activated,
.ps-light-hover-bgc-lighter-i:hover {
  background-color: white !important; }

.ps-light-bgc-bolder,
.ps-light-hover-bgc-bolder.activated,
.ps-light-hover-bgc-bolder:hover {
  background-color: #e6e5e5; }

.ps-light-bgc-bolder-i,
.ps-light-hover-bgc-bolder-i.activated,
.ps-light-hover-bgc-bolder-i:hover {
  background-color: #e6e5e5 !important; }

.ps-btn-action-light:not(.ps-modal-btn) .__icon {
  color: #fff; }

.ps-btn-action-light:not(.ps-modal-btn):hover .__icon {
  color: #e6e5e5; }

.ps-btn-action-light.ps-modal-btn {
  background-color: #fff; }
  .ps-btn-action-light.ps-modal-btn:hover {
    background-color: #e6e5e5; }

.ps-auto-color,
.ps-auto-hover-color.activated,
.ps-auto-hover-color:hover {
  color: #97096a; }

.ps-auto-color-i,
.ps-auto-hover-color-i.activated,
.ps-auto-hover-color-i:hover {
  color: #97096a !important; }

.ps-auto-color-lighter,
.ps-auto-hover-color-lighter.activated,
.ps-auto-hover-color-lighter:hover {
  color: #c70c8c; }

.ps-auto-color-lighter-i,
.ps-auto-hover-color-lighter-i.activated,
.ps-auto-hover-color-lighter-i:hover {
  color: #c70c8c !important; }

.ps-auto-color-bolder,
.ps-auto-hover-color-bolder.activated,
.ps-auto-hover-color-bolder:hover {
  color: #670648; }

.ps-auto-color-bolder-i,
.ps-auto-hover-color-bolder-i.activated,
.ps-auto-hover-color-bolder-i:hover {
  color: #670648 !important; }

.ps-auto-bgc,
.ps-auto-hover-bgc.activated,
.ps-auto-hover-bgc:hover {
  background-color: #97096a; }

.ps-auto-bgc-i,
.ps-auto-hover-bgc-i.activated,
.ps-auto-hover-bgc-i:hover {
  background-color: #97096a !important; }

.ps-auto-bgc-lighter,
.ps-auto-hover-bgc-lighter.activated,
.ps-auto-hover-bgc-lighter:hover {
  background-color: #c70c8c; }

.ps-auto-bgc-lighter-i,
.ps-auto-hover-bgc-lighter-i.activated,
.ps-auto-hover-bgc-lighter-i:hover {
  background-color: #c70c8c !important; }

.ps-auto-bgc-bolder,
.ps-auto-hover-bgc-bolder.activated,
.ps-auto-hover-bgc-bolder:hover {
  background-color: #670648; }

.ps-auto-bgc-bolder-i,
.ps-auto-hover-bgc-bolder-i.activated,
.ps-auto-hover-bgc-bolder-i:hover {
  background-color: #670648 !important; }

.ps-btn-action-auto:not(.ps-modal-btn) .__icon {
  color: #97096a; }

.ps-btn-action-auto:not(.ps-modal-btn):hover .__icon {
  color: #670648; }

.ps-btn-action-auto.ps-modal-btn {
  background-color: #97096a; }
  .ps-btn-action-auto.ps-modal-btn:hover {
    background-color: #670648; }

.ps-scheduled-color,
.ps-scheduled-hover-color.activated,
.ps-scheduled-hover-color:hover {
  color: #331bb0; }

.ps-scheduled-color-i,
.ps-scheduled-hover-color-i.activated,
.ps-scheduled-hover-color-i:hover {
  color: #331bb0 !important; }

.ps-scheduled-color-lighter,
.ps-scheduled-hover-color-lighter.activated,
.ps-scheduled-hover-color-lighter:hover {
  color: #4022dc; }

.ps-scheduled-color-lighter-i,
.ps-scheduled-hover-color-lighter-i.activated,
.ps-scheduled-hover-color-lighter-i:hover {
  color: #4022dc !important; }

.ps-scheduled-color-bolder,
.ps-scheduled-hover-color-bolder.activated,
.ps-scheduled-hover-color-bolder:hover {
  color: #261484; }

.ps-scheduled-color-bolder-i,
.ps-scheduled-hover-color-bolder-i.activated,
.ps-scheduled-hover-color-bolder-i:hover {
  color: #261484 !important; }

.ps-scheduled-bgc,
.ps-scheduled-hover-bgc.activated,
.ps-scheduled-hover-bgc:hover {
  background-color: #331bb0; }

.ps-scheduled-bgc-i,
.ps-scheduled-hover-bgc-i.activated,
.ps-scheduled-hover-bgc-i:hover {
  background-color: #331bb0 !important; }

.ps-scheduled-bgc-lighter,
.ps-scheduled-hover-bgc-lighter.activated,
.ps-scheduled-hover-bgc-lighter:hover {
  background-color: #4022dc; }

.ps-scheduled-bgc-lighter-i,
.ps-scheduled-hover-bgc-lighter-i.activated,
.ps-scheduled-hover-bgc-lighter-i:hover {
  background-color: #4022dc !important; }

.ps-scheduled-bgc-bolder,
.ps-scheduled-hover-bgc-bolder.activated,
.ps-scheduled-hover-bgc-bolder:hover {
  background-color: #261484; }

.ps-scheduled-bgc-bolder-i,
.ps-scheduled-hover-bgc-bolder-i.activated,
.ps-scheduled-hover-bgc-bolder-i:hover {
  background-color: #261484 !important; }

.ps-btn-action-scheduled:not(.ps-modal-btn) .__icon {
  color: #331bb0; }

.ps-btn-action-scheduled:not(.ps-modal-btn):hover .__icon {
  color: #261484; }

.ps-btn-action-scheduled.ps-modal-btn {
  background-color: #331bb0; }
  .ps-btn-action-scheduled.ps-modal-btn:hover {
    background-color: #261484; }

.ps-prioritize-color,
.ps-prioritize-hover-color.activated,
.ps-prioritize-hover-color:hover {
  color: #9433df; }

.ps-prioritize-color-i,
.ps-prioritize-hover-color-i.activated,
.ps-prioritize-hover-color-i:hover {
  color: #9433df !important; }

.ps-prioritize-color-lighter,
.ps-prioritize-hover-color-lighter.activated,
.ps-prioritize-hover-color-lighter:hover {
  color: #ab5fe6; }

.ps-prioritize-color-lighter-i,
.ps-prioritize-hover-color-lighter-i.activated,
.ps-prioritize-hover-color-lighter-i:hover {
  color: #ab5fe6 !important; }

.ps-prioritize-color-bolder,
.ps-prioritize-hover-color-bolder.activated,
.ps-prioritize-hover-color-bolder:hover {
  color: #7a1ec1; }

.ps-prioritize-color-bolder-i,
.ps-prioritize-hover-color-bolder-i.activated,
.ps-prioritize-hover-color-bolder-i:hover {
  color: #7a1ec1 !important; }

.ps-prioritize-bgc,
.ps-prioritize-hover-bgc.activated,
.ps-prioritize-hover-bgc:hover {
  background-color: #9433df; }

.ps-prioritize-bgc-i,
.ps-prioritize-hover-bgc-i.activated,
.ps-prioritize-hover-bgc-i:hover {
  background-color: #9433df !important; }

.ps-prioritize-bgc-lighter,
.ps-prioritize-hover-bgc-lighter.activated,
.ps-prioritize-hover-bgc-lighter:hover {
  background-color: #ab5fe6; }

.ps-prioritize-bgc-lighter-i,
.ps-prioritize-hover-bgc-lighter-i.activated,
.ps-prioritize-hover-bgc-lighter-i:hover {
  background-color: #ab5fe6 !important; }

.ps-prioritize-bgc-bolder,
.ps-prioritize-hover-bgc-bolder.activated,
.ps-prioritize-hover-bgc-bolder:hover {
  background-color: #7a1ec1; }

.ps-prioritize-bgc-bolder-i,
.ps-prioritize-hover-bgc-bolder-i.activated,
.ps-prioritize-hover-bgc-bolder-i:hover {
  background-color: #7a1ec1 !important; }

.ps-btn-action-prioritize:not(.ps-modal-btn) .__icon {
  color: #9433df; }

.ps-btn-action-prioritize:not(.ps-modal-btn):hover .__icon {
  color: #7a1ec1; }

.ps-btn-action-prioritize.ps-modal-btn {
  background-color: #9433df; }
  .ps-btn-action-prioritize.ps-modal-btn:hover {
    background-color: #7a1ec1; }

.ps-bulk-select-wrapper {
  position: relative;
  border: 1px solid #ddd;
  display: inline-block;
  padding-right: 22px;
  height: 24px;
  padding-top: 3px;
  padding-left: 3px; }
  .ps-bulk-select-wrapper .ps-btn.ps-bulk-select-btn {
    position: absolute;
    height: 100%;
    width: 22px;
    font-size: 14px;
    color: #666;
    top: 0;
    right: 0;
    border: 0;
    background-color: transparent; }

body .custom-autocomplete-button {
  font-size: 0.8em;
  width: 22px; }

.button-group button {
  margin-bottom: 0.5em; }

body .ui-table .ui-table-thead > tr > th {
  color: #666; }

body .ui-table .ui-table-tbody > tr.ui-contextmenu-selected {
  background-color: #f5f5f5;
  color: #666; }

body .ui-table .ui-table-tbody tr.row-selected {
  background-color: #eaf5ff; }

body .ui-table .ui-table-thead > tr > th,
body .ui-table .ui-table-tbody > tr > td,
body .ui-table .ui-table-tfoot > tr > td {
  padding: 4px 5px;
  outline: none; }

p-table .ps-table-paginator {
  font-weight: normal;
  text-align: left; }

p-table p-sorticon {
  font-size: 10px; }

.ps-header-cell {
  display: flex;
  justify-content: space-between; }
  .ps-header-cell .__title + .__filter {
    margin-left: 4px; }
  .ps-header-cell .__title {
    flex: 1 10; }
  .ps-header-cell .ui-multiselect {
    background-color: transparent;
    border: 0; }
  .ps-header-cell .ui-multiselect-label-container {
    display: none; }
  .ps-header-cell .ui-multiselect:not(.ui-state-disabled).ui-state-focus {
    box-shadow: none;
    border: 0; }
  .ps-header-cell .ui-multiselect .ui-multiselect-trigger {
    position: static;
    line-height: inherit;
    width: 16px; }
  .ps-header-cell .ui-multiselect .ui-multiselect-trigger .ui-multiselect-trigger-icon {
    position: static;
    margin: 0; }

.ps-table-filter-container .__filter-icon {
  padding-left: 8px; }

.ps-table-filter-overlay-wrapper {
  position: fixed;
  display: block !important;
  text-align: left;
  width: 220px;
  font-weight: 400; }
  .ps-table-filter-overlay-wrapper .ui-overlaypanel-content {
    padding: 0; }

.ps-table-filter-overlay .__filter-area {
  padding: 5px 10px 10px;
  border-bottom: 1px solid #ddd; }
  .ps-table-filter-overlay .__filter-area .__title {
    font-weight: bold;
    margin-bottom: 5px; }

.ps-table-filter-overlay .__filter-area.area-1 .__operator {
  display: block; }

.ps-table-filter-overlay .__filter-area.area-1 .__value {
  margin-top: 5px;
  width: 100%; }

.ps-table-filter-overlay .__filter-area.area-1 .__connective-block {
  padding: 5px 0; }
  .ps-table-filter-overlay .__filter-area.area-1 .__connective-block p-radioButton {
    display: inline-block;
    margin-right: 10px; }

.ps-table-filter-overlay .__filter-area.area-2 .__search-box {
  width: 100%; }

.ps-table-filter-overlay .__filter-area.area-2 .__col-values-actions {
  padding: 5px 0;
  text-align: right; }
  .ps-table-filter-overlay .__filter-area.area-2 .__col-values-actions .__action:hover {
    text-decoration: underline; }
  .ps-table-filter-overlay .__filter-area.area-2 .__col-values-actions .__action + .__action {
    margin-left: 5px; }

.ps-table-filter-overlay .__filter-area.area-2 .__col-value-items {
  max-height: 150px;
  border: 1px solid #ddd;
  padding: 3px 0;
  overflow-y: auto; }
  .ps-table-filter-overlay .__filter-area.area-2 .__col-value-items p-checkbox {
    display: flex;
    padding: 3px 8px; }
    .ps-table-filter-overlay .__filter-area.area-2 .__col-value-items p-checkbox > label {
      flex: 1; }
    .ps-table-filter-overlay .__filter-area.area-2 .__col-value-items p-checkbox:hover {
      background-color: #eeeeee; }

.ps-table-filter-overlay .__action-area {
  text-align: right;
  padding: 10px; }
  .ps-table-filter-overlay .__action-area .ps-btn + .ps-btn {
    margin-left: 5px; }

p-table.ps-table {
  display: block; }
  p-table.ps-table, p-table.ps-table .ui-table {
    width: 100%; }
  p-table.ps-table .ui-table-wrapper {
    overflow-x: auto; }
  p-table.ps-table.inherit-max-height, p-table.ps-table.inherit-max-height .ui-table,
  p-table.ps-table.inherit-max-height .ui-table-wrapper,
  p-table.ps-table.inherit-max-height .ui-table-scrollable-wrapper,
  p-table.ps-table.inherit-max-height .ui-table-scrollable-view {
    max-height: inherit; }
  p-table.ps-table .ui-widget-header {
    border: 0; }
  p-table.ps-table thead {
    background: #eee; }
  p-table.ps-table .ui-widget-overlay {
    background: #fff; }
  p-table.ps-table p-paginator .ui-paginator {
    text-align: right;
    padding: 10px 15px; }
    p-table.ps-table p-paginator .ui-paginator .ui-paginator-first,
    p-table.ps-table p-paginator .ui-paginator .ui-paginator-prev,
    p-table.ps-table p-paginator .ui-paginator .ui-paginator-next,
    p-table.ps-table p-paginator .ui-paginator .ui-paginator-last {
      margin-right: 0;
      margin-left: 0;
      min-width: 28px;
      min-height: 28px; }
    p-table.ps-table p-paginator .ui-paginator .ui-paginator-page {
      min-width: 28px;
      min-height: 28px;
      padding-left: 6px;
      padding-right: 6px; }
  p-table.ps-table td input[type="text"],
  p-table.ps-table td input[type="search"],
  p-table.ps-table td input[type="email"],
  p-table.ps-table td input[type="url"],
  p-table.ps-table td input[type="password"],
  p-table.ps-table td input[type="tel"],
  p-table.ps-table td input[type="number"],
  p-table.ps-table td input[type="date"] {
    padding-left: 6px;
    padding-right: 6px; }
  p-table.ps-table td .ui-dropdown {
    min-width: 0; }

body .ui-table .ui-table-tbody > tr.highlight-border-bottom > td {
  border-bottom: 1px solid #bbb; }

body .ui-table .ui-table-tbody > tr.highlight > td {
  border: 1px solid #bbb; }

body .ui-table .ui-table-tbody > tr.highlight {
  background-color: #f2f2f2; }

body .ui-table .ui-table-tbody > tr:nth-child(even).highlight {
  background-color: #e9e9e9; }

body .ui-table span.ui-sortable-column.ui-state-highlight {
  background-color: transparent;
  outline: none; }

body .ui-table span.ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: #1ca7ff; }

p-table.align-cell-top .ui-table .ui-table-tbody > tr > td {
  vertical-align: top; }

p-table.no-border > .ui-table tr > td, p-table.no-border > .ui-table tr > th {
  border: 0; }

.ps-table-footer {
  background: #fff;
  width: 100%;
  align-items: center;
  border: 1px solid #dddddd;
  border-top: none;
  padding: 15px; }

body .ui-table .ui-table-tbody > tr > td.ps-td-sunday {
  background-color: #d9d9d9; }

/* Customize Paging */
body .custom-paginator .ui-widget-header {
  font-weight: normal; }

body .custom-paginator .ui-paginator-element {
  border: none; }
  body .custom-paginator .ui-paginator-element.ui-state-disabled {
    background: none;
    color: #9e9e9e; }

body .custom-paginator .ui-paginator-pages .ui-paginator-page {
  border: none; }

body .custom-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  border: none;
  background-color: #f3f3f3;
  color: #579cd8; }
  body .custom-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active:enabled:hover {
    background-color: #ccc; }
  body .custom-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active:focus {
    background-color: #f3f3f3;
    border: none;
    box-shadow: none; }

/* End custom paging */
body .ui-fileupload {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.ui-fileupload .ui-fileupload-buttonbar {
  display: none; }

.ui-fileupload .ui-fileupload-content {
  background-color: #fff;
  border-style: dashed;
  border-color: #1ca7ff;
  text-align: center; }

.ui-fileupload .choose-file {
  background-color: #d9ebf7;
  padding: 5px;
  border-radius: 0; }

p-fileupload.ps-file-upload.style-1 .ui-fileupload {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }
  p-fileupload.ps-file-upload.style-1 .ui-fileupload .ui-fileupload-buttonbar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    border: 1px dashed #bbb;
    border-radius: 0;
    background-color: transparent;
    font-weight: 400;
    font-size: 14px; }
  p-fileupload.ps-file-upload.style-1 .ui-fileupload .ui-fileupload-choose {
    position: static;
    height: 30px;
    line-height: 28px;
    padding: 0 10px;
    margin-right: 0;
    margin-left: 5px;
    border-radius: 0;
    color: #212529;
    background-color: rgba(3, 134, 208, 0.3);
    border: 1px solid rgba(3, 134, 208, 0.6);
    cursor: pointer;
    width: auto; }
    p-fileupload.ps-file-upload.style-1 .ui-fileupload .ui-fileupload-choose input[type=file] {
      width: 100%;
      height: 100%; }
    p-fileupload.ps-file-upload.style-1 .ui-fileupload .ui-fileupload-choose .ui-button-icon-left {
      display: none; }
    p-fileupload.ps-file-upload.style-1 .ui-fileupload .ui-fileupload-choose .ui-button-text {
      padding: 0;
      display: block;
      line-height: inherit; }
  p-fileupload.ps-file-upload.style-1 .ui-fileupload .ui-fileupload-content {
    border: 0; }

.ps-status {
  color: #fff;
  display: inline-block;
  font-size: 11px;
  border-radius: 2px;
  padding: 5px 7px; }

.ps-status-doing {
  background: #1ca7ff; }

.text-status-doing {
  color: #1ca7ff; }

.ps-status-response {
  background: #7c8fce; }

.text-status-response {
  color: #7c8fce; }

.ps-status-done {
  background: #004a80; }

.text-status-done {
  color: #004a80; }

.ps-status-waiting {
  background: #e8b837; }

.text-status-waiting {
  color: #e8b837; }

.ps-status-valid {
  background: #65bd43; }

.text-status-valid {
  color: #65bd43; }

.ps-status-expire {
  background: #f44c4c; }

.text-status-expire {
  color: #f44c4c; }

.ps-status-lock {
  background: #b37d1f; }

.text-status-lock {
  color: #b37d1f; }

.ps-status-cancel {
  background: #999; }

.text-status-cancel {
  color: #999; }

.ps-status-info {
  background: #2e9fe0; }

.text-status-info {
  color: #2e9fe0; }

.ps-status-leave {
  background: #973707; }

.text-status-leave {
  color: #973707; }

.ps-status-pause {
  background: #b44400; }

.text-status-pause {
  color: #b44400; }

.ps-status-stop {
  background: #e8822f; }

.text-status-stop {
  color: #e8822f; }

.ps-status-back {
  background: #666; }

.text-status-back {
  color: #666; }

body .ui-tree .ui-treenode .ui-treenode-content {
  padding-top: 2px;
  padding-bottom: 6px; }

.ps-data-tree.hide-search-input .ui-tree-filter-container {
  display: none; }

.ps-data-tree .ui-tree {
  border: 0;
  width: auto; }

.ps-data-tree .ui-treenode-content {
  outline: none;
  border: 1px solid transparent; }

.ps-data-tree .ui-treenode-icon {
  padding-left: 3px;
  padding-right: 5px; }

.ps-data-tree:not([selectionmode="checkbox"]) .ui-treenode-content.ui-treenode-content-selected {
  border: 1px solid #1ca7ff;
  background-color: rgba(28, 167, 255, 0.2); }

.ps-data-tree:not([selectionmode="checkbox"]) .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
  background-color: transparent;
  color: inherit; }

.ps-data-tree[selectionmode="checkbox"] .ui-treenode .ui-treenode-content {
  padding: 0; }
  .ps-data-tree[selectionmode="checkbox"] .ui-treenode .ui-treenode-content .ui-treenode-label {
    padding: 5px; }

body .ui-calendar.ui-calendar-w-btn .ui-inputtext,
body .ui-calendar.ui-calendar-w-btn .ui-inputtext:enabled:hover:not(.ui-state-error),
body .ui-calendar.ui-calendar-w-btn .ui-inputtext:enabled:focus:not(.ui-state-error) {
  border-right: 1px solid #ddd;
  padding-right: 28px; }

p-calendar .ui-calendar {
  width: 100%; }
  p-calendar .ui-calendar .ui-inputtext {
    width: 100%;
    border-radius: 0px; }

p-calendar.ps-date-picker .ui-calendar-w-btn {
  position: relative; }
  p-calendar.ps-date-picker .ui-calendar-w-btn .ui-button {
    border-radius: 0;
    position: absolute;
    background-color: transparent;
    right: 0;
    color: #aaa; }
  p-calendar.ps-date-picker .ui-calendar-w-btn .ui-button.ui-button-icon-only .ui-button-text {
    display: none; }

p-tabView.ps-tab-view.style-1 .ui-tabview.ui-tabview-top .ui-tabview-nav li {
  outline: none; }

p-tabView.ps-tab-view.style-1 .ui-tabview.ui-tabview-top .ui-tabview-nav li a {
  color: #666;
  padding: 5px 10px; }

p-tabView.ps-tab-view.style-1 .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a {
  font-weight: 700;
  color: #212529; }

p-tabView.ps-tab-view.style-1 .ui-tabview-panel {
  padding: 0; }

p-tabView.ps-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav {
  display: flex;
  flex-wrap: wrap; }
  p-tabView.ps-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav li {
    background-color: #1ca7ff;
    border: none;
    margin-right: 0;
    border-radius: 0;
    flex: 1 0 110px;
    max-width: 200px; }
    @media screen and (max-width: 640px) {
      p-tabView.ps-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav li {
        width: 100%; } }
    p-tabView.ps-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav li a {
      color: #fff;
      font-weight: bold; }
    p-tabView.ps-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav li:focus {
      outline: none; }
    p-tabView.ps-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active {
      background-color: #fff; }
      p-tabView.ps-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a {
        color: #0a89bf; }
    p-tabView.ps-tab-view-info-account .ui-tabview.ui-tabview-top .ui-tabview-nav li:hover a {
      color: #666666; }

p-tabView.ps-tab-view-info-account .ui-tabview.ui-tabview-top .ps-btn.__btn-accordion {
  padding: 0px 10px;
  margin-right: 5px;
  border-radius: 0; }

p-tabView.ps-tab-view-info-account .ui-tabview.ui-tabview-top .ps-btn.activated {
  background-color: #14b545; }

p-tabView.ps-tab-view-info-account .ui-tabview-panel {
  padding: 0; }
  p-tabView.ps-tab-view-info-account .ui-tabview-panel .__content-body {
    border: 1px solid #ddd; }
  p-tabView.ps-tab-view-info-account .ui-tabview-panel .__top-tabview-action {
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 5px; }

.ps-tooltip .ui-tooltip-text {
  word-break: break-word; }

.layout-wrapper .topbar .topbar-right,
.layout-wrapper .topbar .topbar-left {
  background: #00a651; }

.layout-wrapper .topbar,
.layout-wrapper .topbar .topbar-right,
.layout-wrapper .topbar .topbar-left {
  height: 40px; }

.layout-wrapper .topbar .topbar-right {
  padding: 5px 15px; }

.layout-wrapper .topbar .topbar-left {
  width: 240px; }
  .layout-wrapper .topbar .topbar-left .topbar-logo {
    height: auto;
    width: 50%; }
  .layout-wrapper .topbar .topbar-left .topbar-title {
    padding: 4px 13px 4px 0;
    height: 40px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .layout-wrapper .topbar .topbar-left .topbar-title .topbar-description {
      font-size: 12px;
      font-weight: 700; }
    .layout-wrapper .topbar .topbar-left .topbar-title .topbar-owner {
      font-size: 10px;
      margin-bottom: 0; }

.layout-wrapper .topbar .topbar-right #menu-button {
  left: 240px;
  top: 8px;
  width: 24px;
  height: 24px;
  margin-left: -12px; }
  .layout-wrapper .topbar .topbar-right #menu-button i {
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .layout-wrapper .topbar .topbar-right #menu-button i:before {
    margin-top: -1px;
    margin-left: -1px; }

.layout-wrapper .topbar-items > li > a .topbar-badge {
  background-color: #e65025;
  font-weight: bold; }

.layout-wrapper .layout-menu li a i:first-child {
  width: 40px;
  text-align: center;
  font-size: 1.6em;
  margin-right: 5px; }

.layout-wrapper .layout-menu li a i:first-child,
.layout-wrapper .layout-menu li.active-menuitem > a i:first-child {
  color: #666565; }

.layout-wrapper .layout-menu li a span {
  line-height: 1.6em; }

.layout-wrapper .topbar-items > li.profile-item a .profile-image {
  border: 2px #fff solid;
  border-radius: 50%; }

@media (min-width: 1025px) {
  .layout-wrapper .topbar-items > li > ul.subsystem {
    width: 300px; }
    .layout-wrapper .topbar-items > li > ul.subsystem a.subsystem-item {
      width: 50%;
      display: inline-block; }
    .layout-wrapper .topbar-items > li > ul.subsystem:before {
      left: auto;
      right: 3px; }
  .layout-wrapper .topbar-items {
    margin: -10px 0 0;
    display: flex;
    flex-direction: row-reverse;
    height: 50px;
    align-items: center; }
    .layout-wrapper .topbar-items > li {
      float: none; }
  .layout-wrapper .topbar-items > li.profile-item a {
    top: 0; }
  .layout-wrapper .topbar-items > li .topbar-icon {
    font-size: 20px; }
  .layout-wrapper .topbar-items > li.profile-item a .profile-image {
    width: 32px;
    height: 32px;
    margin-top: 0;
    margin-left: 8px; }
  .layout-wrapper .topbar-items > li.profile-item {
    margin-left: 10px; }
    .layout-wrapper .topbar-items > li.profile-item .profile-item-inner {
      display: flex;
      align-items: center; }
      .layout-wrapper .topbar-items > li.profile-item .profile-item-inner .topbar-item-name {
        float: none; }
    .layout-wrapper .topbar-items > li.profile-item .topbar-item-name {
      white-space: nowrap; }
    .layout-wrapper .topbar-items > li.profile-item .topbar-item-role {
      line-height: 20px;
      white-space: nowrap; }
  .layout-wrapper .topbar-items > li.search-item .ui-inputtext {
    width: 250px; } }

@media (max-width: 1024px) {
  .layout-wrapper .topbar .topbar-right .topbar-items > li.profile-item > a {
    padding-left: 50px; }
  .layout-wrapper .topbar .topbar-right .topbar-items > li.profile-item.active-top-menu > ul li a {
    padding-left: 5px; }
  .layout-wrapper .topbar-items > li.profile-item > ul {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
  .layout-wrapper .topbar-items > li.profile-item .topbar-item-role {
    opacity: 0.7;
    font-size: 14px; }
  .layout-wrapper .topbar-items > li.profile-item .profile-image-wrapper {
    position: absolute;
    top: 50%;
    margin-top: -12px;
    left: 15px; }
  .layout-wrapper .topbar-items > li.search-item .ui-inputtext {
    width: 100%;
    outline: none; } }

@media (min-width: 1025px) {
  .noti-item {
    border-bottom: 1px solid #ddd;
    box-sizing: border-box; }
    .noti-item.un-read {
      background: #eaf5ff; }
  .layout-wrapper .topbar-items > li > ul.custom-notification {
    width: 350px; }
    .layout-wrapper .topbar-items > li > ul.custom-notification::before {
      left: 332px; }
  .noti-content {
    font-size: 0.9em; }
    .noti-content .noti-title {
      font-weight: bold;
      margin-bottom: 0.5em; }
    .noti-content .noti-message {
      margin-bottom: 0.5em;
      font-size: 0.9em;
      text-align: justify; }
    .noti-content .noti-time {
      font-size: 0.7em; }
  .noti-footer {
    text-align: center;
    font-size: 0.9em; }
  .noti-header {
    font-size: 0.9em;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd; }
  .layout-wrapper .layout-menu li div.noti-time i:first-child {
    margin-right: 0px;
    width: 15px;
    line-height: 15px; }
  .noti-avatar {
    float: left;
    height: 50px; } }

.layout-wrapper .layout-menu-container {
  top: 40px;
  padding-bottom: 70px;
  width: 240px; }

.layout-wrapper .layout-menu-container .ui-scrollpanel .layout-main-menu {
  margin-top: 0;
  padding-bottom: 0; }

.ps-left-menu-area .menu-scroll-content-area .ui-scrollpanel-wrapper {
  z-index: 3; }

.ps-left-menu-area .search-area {
  position: absolute;
  width: 100%;
  height: 30px;
  left: 0;
  bottom: 40px;
  border-top: 1px solid #ccc; }
  .ps-left-menu-area .search-area .search-btn {
    border-radius: 0;
    height: 100%; }
  .ps-left-menu-area .search-area .search-input, .ps-left-menu-area .search-area .search-input .ui-autocomplete {
    display: block;
    width: 100%;
    height: 100%; }
  .ps-left-menu-area .search-area .search-input .ui-inputtext {
    padding-right: 30px;
    padding-left: 15px;
    width: 100%;
    height: 100%;
    outline: none;
    border: 0; }
    .ps-left-menu-area .search-area .search-input .ui-inputtext:enabled:focus:not(.ui-state-error) {
      border: 0;
      box-shadow: none; }
  .ps-left-menu-area .search-area .search-input .ui-autocomplete-items {
    padding: 10px 5px; }
  .ps-left-menu-area .search-area .search-input .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item.ui-state-highlight {
    color: inherit;
    background-color: rgba(28, 167, 255, 0.3); }
  .ps-left-menu-area .search-area .search-input .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item mark {
    background: transparent;
    color: #1ca7ff; }
  .ps-left-menu-area .search-area .search-btn {
    position: absolute;
    right: 0;
    top: 0;
    color: #888;
    width: 30px;
    font-size: 14px; }
    .ps-left-menu-area .search-area .search-btn, .ps-left-menu-area .search-area .search-btn:enabled:hover, .ps-left-menu-area .search-area .search-btn:enabled:active, .ps-left-menu-area .search-area .search-btn:enabled:focus {
      background-color: transparent;
      box-shadow: none; }

.ps-main-menu li > a.ripplelink,
.ps-main-menu li.activated.active-menuitem > a.ripplelink {
  color: inherit; }

.ps-main-menu li.activated > a.ripplelink {
  color: #337ab7; }

.ps-main-menu li a.ripplelink i:first-child {
  margin-right: 0; }

.ps-main-menu a.ripplelink {
  cursor: pointer;
  position: relative; }
  .ps-main-menu a.ripplelink > span.menuitem-label {
    line-height: 22px; }

.ps-main-menu .menuitem-level-0 > .ripplelink {
  padding: 0 0 0 46px;
  line-height: 45px; }
  .ps-main-menu .menuitem-level-0 > .ripplelink .menuitem-label {
    font-weight: 700; }
  .ps-main-menu .menuitem-level-0 > .ripplelink .menuitem-toggle-icon {
    color: inherit;
    position: absolute;
    top: 7px;
    width: 20px;
    right: 10px; }
  .ps-main-menu .menuitem-level-0 > .ripplelink .main-icon {
    position: absolute;
    width: 32px;
    line-height: 45px;
    left: 6px; }

.ps-main-menu .menuitem-level-1 > .ripplelink {
  padding: 5px 24px 5px 46px; }
  .ps-main-menu .menuitem-level-1 > .ripplelink .menuitem-toggle-icon, .ps-main-menu .menuitem-level-1 > .ripplelink .main-icon {
    position: absolute;
    width: 22px;
    height: 22px;
    margin-top: 0;
    margin-right: 0;
    color: inherit;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 19px; }
  .ps-main-menu .menuitem-level-1 > .ripplelink .menuitem-toggle-icon {
    opacity: 0; }
  .ps-main-menu .menuitem-level-1 > .ripplelink .main-icon {
    font-size: 18px;
    opacity: 1; }

.ps-main-menu .menuitem-level-1:hover > .ripplelink {
  background-color: #f4f4f4; }

.ps-main-menu .menuitem-level-1.has-submenu:not(.allow-click) > .ripplelink .menuitem-toggle-icon {
  opacity: 1; }

.ps-main-menu .menuitem-level-1.has-submenu:not(.allow-click) > .ripplelink .main-icon {
  opacity: 0; }

@media (min-width: 1025px) {
  .ps-main-menu .menuitem-level-1 {
    position: relative; }
    .ps-main-menu .menuitem-level-1:hover > .ripplelink .menuitem-toggle-icon:before {
      -webkit-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      transform: rotate(90deg); }
    .ps-main-menu .menuitem-level-1:hover .submenu-level-2 {
      opacity: 1;
      left: 240px;
      width: 450px; } }

@media (max-width: 1024px) {
  .ps-main-menu .menuitem-level-1.activated > .ripplelink .menuitem-toggle-icon:before {
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    transform: rotate(90deg); } }

.ps-main-menu .menuitem-level-2 .main-icon {
  display: none; }

@media (min-width: 1025px) {
  .ps-main-menu .submenu-level-2 {
    overflow-y: auto !important;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    position: fixed;
    left: 250px;
    top: 40px;
    z-index: 1001;
    width: 0;
    height: calc(100vh - 40px) !important;
    background: #99dbf5;
    transition: opacity .3s, left .3s;
    opacity: 0; }
  .ps-main-menu .menuitem-level-2 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    position: relative; }
    .ps-main-menu .menuitem-level-2 .pinned-icon {
      -webkit-transition: opacity 0.3s ease-out;
      -o-transition: opacity 0.3s ease-out;
      transition: opacity 0.3s ease-out;
      position: absolute;
      left: 50%;
      z-index: 10;
      top: 28px;
      transform: translateX(7px);
      width: 30px;
      height: 30px;
      color: #fff;
      font-size: 14px;
      opacity: 0;
      cursor: pointer; }
      .ps-main-menu .menuitem-level-2 .pinned-icon:before {
        position: absolute;
        line-height: 24px;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center; }
    .ps-main-menu .menuitem-level-2 > .ripplelink, .ps-main-menu .menuitem-level-2 > .ripplelink.active-menuitem-routerlink {
      outline: none;
      padding: 30px 15px;
      text-align: center;
      border-left: 0; }
      .ps-main-menu .menuitem-level-2 > .ripplelink .decoration, .ps-main-menu .menuitem-level-2 > .ripplelink.active-menuitem-routerlink .decoration {
        -webkit-transition: background-color 0.3s ease-out;
        -o-transition: background-color 0.3s ease-out;
        transition: background-color 0.3s ease-out;
        background-color: #4facce;
        color: #fff;
        display: block;
        width: 60px;
        height: 60px;
        margin: 0 auto 10px;
        position: relative; }
      .ps-main-menu .menuitem-level-2 > .ripplelink .decorated-icon, .ps-main-menu .menuitem-level-2 > .ripplelink.active-menuitem-routerlink .decorated-icon {
        color: inherit; }
        .ps-main-menu .menuitem-level-2 > .ripplelink .decorated-icon:before, .ps-main-menu .menuitem-level-2 > .ripplelink.active-menuitem-routerlink .decorated-icon:before {
          position: absolute;
          left: 0;
          bottom: 10px;
          width: 100%;
          font-size: 32px;
          display: block; }
      .ps-main-menu .menuitem-level-2 > .ripplelink .menuitem-label, .ps-main-menu .menuitem-level-2 > .ripplelink.active-menuitem-routerlink .menuitem-label {
        -webkit-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        color: #035378;
        line-height: 1.2em; }
    .ps-main-menu .menuitem-level-2:hover .pinned-icon {
      opacity: 1; }
    .ps-main-menu .menuitem-level-2:hover > .ripplelink, .ps-main-menu .menuitem-level-2:hover > .ripplelink.active-menuitem-routerlink {
      background: transparent; }
      .ps-main-menu .menuitem-level-2:hover > .ripplelink .decoration, .ps-main-menu .menuitem-level-2:hover > .ripplelink.active-menuitem-routerlink .decoration {
        background-color: #3394b7; }
      .ps-main-menu .menuitem-level-2:hover > .ripplelink .menuitem-label, .ps-main-menu .menuitem-level-2:hover > .ripplelink.active-menuitem-routerlink .menuitem-label {
        color: #023146; }
    .ps-main-menu .menuitem-level-2.pinned .pinned-icon {
      opacity: 1; } }

@media (max-width: 1024px) {
  .ps-main-menu .menuitem-level-2 > .ripplelink {
    padding-left: 60px; }
    .ps-main-menu .menuitem-level-2 > .ripplelink .decoraticon {
      display: none; } }

.ps-pinned-menu {
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  position: fixed;
  left: 250px;
  top: 40px;
  z-index: 1000;
  width: 0;
  height: calc(100vh - 40px);
  background: #99dbf5;
  transition: opacity .3s, left .3s;
  opacity: 0;
  padding: 0;
  margin: 0;
  list-style: none; }
  .ps-pinned-menu.show {
    opacity: 1;
    left: 240px;
    width: 450px; }
  .ps-pinned-menu li {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    position: relative; }
    .ps-pinned-menu li .pinned-icon {
      -webkit-transition: opacity 0.3s ease-out;
      -o-transition: opacity 0.3s ease-out;
      transition: opacity 0.3s ease-out;
      position: absolute;
      left: 50%;
      z-index: 10;
      top: 28px;
      transform: translateX(7px);
      width: 30px;
      height: 30px;
      color: #fff;
      font-size: 14px;
      opacity: 0;
      cursor: pointer; }
      .ps-pinned-menu li .pinned-icon:before {
        position: absolute;
        line-height: 24px;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center; }
    .ps-pinned-menu li > .ripplelink, .ps-pinned-menu li > .ripplelink.active-menuitem-routerlink {
      display: block;
      outline: none;
      padding: 30px 15px;
      text-align: center;
      border-left: 0; }
      .ps-pinned-menu li > .ripplelink .decoration, .ps-pinned-menu li > .ripplelink.active-menuitem-routerlink .decoration {
        -webkit-transition: background-color 0.3s ease-out;
        -o-transition: background-color 0.3s ease-out;
        transition: background-color 0.3s ease-out;
        background-color: #4facce;
        color: #fff;
        display: block;
        width: 60px;
        height: 60px;
        margin: 0 auto 10px;
        position: relative; }
      .ps-pinned-menu li > .ripplelink .decorated-icon, .ps-pinned-menu li > .ripplelink.active-menuitem-routerlink .decorated-icon {
        color: inherit; }
        .ps-pinned-menu li > .ripplelink .decorated-icon:before, .ps-pinned-menu li > .ripplelink.active-menuitem-routerlink .decorated-icon:before {
          position: absolute;
          left: 0;
          bottom: 10px;
          width: 100%;
          font-size: 32px;
          display: block; }
      .ps-pinned-menu li > .ripplelink .menuitem-label, .ps-pinned-menu li > .ripplelink.active-menuitem-routerlink .menuitem-label {
        -webkit-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        color: #035378;
        line-height: 1.2em; }
    .ps-pinned-menu li:hover .pinned-icon {
      opacity: 1; }
    .ps-pinned-menu li:hover > .ripplelink, .ps-pinned-menu li:hover > .ripplelink.active-menuitem-routerlink {
      background: transparent; }
      .ps-pinned-menu li:hover > .ripplelink .decoration, .ps-pinned-menu li:hover > .ripplelink.active-menuitem-routerlink .decoration {
        background-color: #3394b7; }
      .ps-pinned-menu li:hover > .ripplelink .menuitem-label, .ps-pinned-menu li:hover > .ripplelink.active-menuitem-routerlink .menuitem-label {
        color: #023146; }
    .ps-pinned-menu li.pinned .pinned-icon {
      opacity: 1; }

@media (min-width: 1025px) {
  .layout-wrapper.menu-layout-static.layout-menu-static-desktop-active .ps-left-menu-area,
  .layout-wrapper.menu-layout-static.layout-menu-static-inactive .ps-left-menu-area {
    overflow: hidden;
    -webkit-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease; }
    .layout-wrapper.menu-layout-static.layout-menu-static-desktop-active .ps-left-menu-area > p-scrollpanel,
    .layout-wrapper.menu-layout-static.layout-menu-static-inactive .ps-left-menu-area > p-scrollpanel {
      display: block;
      width: 240px;
      height: 100%; }
      .layout-wrapper.menu-layout-static.layout-menu-static-desktop-active .ps-left-menu-area > p-scrollpanel .menu-scroll-content,
      .layout-wrapper.menu-layout-static.layout-menu-static-inactive .ps-left-menu-area > p-scrollpanel .menu-scroll-content {
        width: 240px;
        margin-right: 0;
        padding-right: 0; }
  .layout-wrapper.menu-layout-static.layout-menu-static-inactive .ps-left-menu-area {
    margin-left: 0;
    width: 50px; }
    .layout-wrapper.menu-layout-static.layout-menu-static-inactive .ps-left-menu-area .submenu-level-1 {
      display: none; }
    .layout-wrapper.menu-layout-static.layout-menu-static-inactive .ps-left-menu-area .search-area .search-input {
      opacity: 0;
      -webkit-transition: opacity 0.3s ease;
      -o-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease; }
    .layout-wrapper.menu-layout-static.layout-menu-static-inactive .ps-left-menu-area .search-area .search-btn {
      width: 50px;
      -webkit-transition: width 0.3s ease;
      -o-transition: width 0.3s ease;
      transition: width 0.3s ease; }
    .layout-wrapper.menu-layout-static.layout-menu-static-inactive .ps-left-menu-area:hover {
      width: 240px; }
      .layout-wrapper.menu-layout-static.layout-menu-static-inactive .ps-left-menu-area:hover .submenu-level-1 {
        display: block; }
      .layout-wrapper.menu-layout-static.layout-menu-static-inactive .ps-left-menu-area:hover .search-area .search-input {
        opacity: 1; }
      .layout-wrapper.menu-layout-static.layout-menu-static-inactive .ps-left-menu-area:hover .search-area .search-btn {
        width: 40px; }
  .layout-wrapper.menu-layout-static.layout-menu-static-inactive .ps-page-content-area {
    margin-left: 50px; } }

@media (max-width: 1024px) {
  .layout-wrapper.menu-layout-static .layout-menu-container {
    margin-left: -308px; } }

.ps-page-wrapper .ps-page-content-area {
  padding: 40px 0 0 0; }

@media (min-width: 1025px) {
  .layout-wrapper.menu-layout-static .layout-main {
    margin-left: 240px; } }

.ps-page-content-header-area {
  background-color: #fff;
  padding: 7.5px 0 0;
  margin-bottom: 7.5px; }
  .ps-page-content-header-area .__page-title {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 5px;
    line-height: 30px; }
  .ps-page-content-header-area .__search-box {
    position: relative;
    margin-bottom: 7.5px; }
    .ps-page-content-header-area .__search-box .__input {
      padding-right: 47px;
      width: 100%; }
    .ps-page-content-header-area .__search-box .__toggle-search-adv, .ps-page-content-header-area .__search-box .__search-btn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 100%;
      top: 0;
      cursor: pointer; }
    .ps-page-content-header-area .__search-box .__toggle-search-adv {
      right: 24px; }
    .ps-page-content-header-area .__search-box .__search-btn {
      right: 0; }
  .ps-page-content-header-area .__search-filter-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap; }
    .ps-page-content-header-area .__search-filter-wrapper > * {
      margin-left: 5px;
      margin-bottom: 5px; }
    .ps-page-content-header-area .__search-filter-wrapper .ui-dropdown, .ps-page-content-header-area .__search-filter-wrapper .ui-multiselect {
      min-width: 180px; }
    .ps-page-content-header-area .__search-filter-wrapper p-calendar > .ui-calendar-w-btn input:hover, .ps-page-content-header-area .__search-filter-wrapper p-calendar > .ui-calendar-w-btn input:focus {
      border-right: 1px solid #ddd !important; }

.ps-data-table-area {
  padding-bottom: 7.5px; }
  .ps-data-table-area.no-padding {
    padding: 0; }
  .ps-data-table-area .__left-table-sidebar, .ps-data-table-area .__right-table-sidebar {
    background: #fff; }
    .ps-data-table-area .__left-table-sidebar .__sidebar-header, .ps-data-table-area .__right-table-sidebar .__sidebar-header {
      background-color: #1ca7ff;
      color: #fff;
      line-height: 16px;
      padding: 4px 10px; }
  .ps-data-table-area .__top-table-header {
    background-color: #1ca7ff;
    color: #fff; }
    .ps-data-table-area .__top-table-header .__left-content {
      display: flex;
      align-items: center;
      min-height: 24px; }
    .ps-data-table-area .__top-table-header .__toggle-left-table-sidebar {
      font-size: 20px;
      margin-right: 10px;
      cursor: pointer; }
    .ps-data-table-area .__top-table-header .__right-content {
      padding-right: 0; }
    .ps-data-table-area .__top-table-header .__action-tabs {
      margin: 0;
      padding: 0;
      list-style: none;
      float: right;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end; }
    .ps-data-table-area .__top-table-header .__tab-item-wrapper {
      height: 24px; }
    .ps-data-table-area .__top-table-header .__tab-item {
      cursor: pointer;
      display: flex;
      height: 100%;
      align-items: center;
      padding-left: 5px;
      padding-right: 5px; }
      .ps-data-table-area .__top-table-header .__tab-item .__icon {
        font-size: 18px;
        margin-right: 5px; }
      .ps-data-table-area .__top-table-header .__tab-item.activated, .ps-data-table-area .__top-table-header .__tab-item:hover {
        background-color: #0386d0; }
    .ps-data-table-area .__top-table-header .__toggle-column-wrapper {
      position: relative; }
    .ps-data-table-area .__top-table-header .__column-list {
      position: absolute;
      max-height: 400px;
      overflow: auto;
      width: 250px;
      top: 100%;
      padding-top: 5px;
      padding-bottom: 5px;
      right: 0;
      z-index: 5;
      background: #fff;
      color: #212529;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3); }
      .ps-data-table-area .__top-table-header .__column-list .__column-item {
        padding: 5px 10px; }
  .ps-data-table-area .__top-table-action {
    background-color: #fff;
    padding-top: 5px; }
    .ps-data-table-area .__top-table-action .__left-content > * {
      vertical-align: top;
      margin-right: 5px;
      margin-bottom: 5px; }
    .ps-data-table-area .__top-table-action .__left-content .ui-dropdown {
      min-width: 180px; }
    .ps-data-table-area .__top-table-action .__change-view {
      text-align: right; }
      .ps-data-table-area .__top-table-action .__change-view .ps-btn {
        margin-left: 5px;
        margin-bottom: 5px;
        color: #fff;
        background: #aaa;
        border: 0;
        padding-left: 10px;
        padding-right: 10px;
        -webkit-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out; }
        .ps-data-table-area .__top-table-action .__change-view .ps-btn:hover, .ps-data-table-area .__top-table-action .__change-view .ps-btn.activated {
          background-color: #14b545; }

.ps-data-tree-container .__data-tree-tools-area {
  padding: 10px;
  border-bottom: 1px solid #ddd; }
  .ps-data-tree-container .__data-tree-tools-area .__expanse-tree-node-btn {
    margin-right: 5px;
    border: 0;
    font-size: 12px;
    color: #fff;
    background-color: #aaa;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    .ps-data-tree-container .__data-tree-tools-area .__expanse-tree-node-btn:hover, .ps-data-tree-container .__data-tree-tools-area .__expanse-tree-node-btn.activated {
      background-color: #14b545; }

body .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
  font-size: 16px; }

.ui-dialog.ui-dialog-resizable {
  height: auto !important; }

.ui-dialog.ui-dialog-maximized {
  max-width: none !important;
  max-height: none !important; }
  .ui-dialog.ui-dialog-maximized .ui-dialog-content {
    max-height: none !important; }

.ps-modal.force-full-width .ui-dialog, .ps-modal.force-full-width.ui-dialog {
  width: 100% !important; }

.ps-modal .ui-dialog, .ps-modal.ui-dialog {
  background-color: #f5f5f5; }

.ps-modal .ui-dialog .ui-dialog-titlebar,
.ps-modal.ui-dialog .ui-dialog-titlebar {
  border-bottom: 0; }
  .ps-modal .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon,
  .ps-modal.ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
    font-size: 20px; }

.ps-modal .ps-modal-tab {
  margin-top: -15px;
  display: block; }
  .ps-modal .ps-modal-tab .ui-tabview .ui-tabview-nav {
    background-color: transparent; }
  .ps-modal .ps-modal-tab .ui-tabview .ui-tabview-nav li {
    margin-right: -3px;
    outline: none;
    border: 3px solid #ccc;
    border-radius: 0;
    border-bottom-color: #2e9fe0; }
    .ps-modal .ps-modal-tab .ui-tabview .ui-tabview-nav li a {
      font-weight: 700;
      color: #212529; }
    .ps-modal .ps-modal-tab .ui-tabview .ui-tabview-nav li:not(.ui-state-active) {
      background-color: #e6e6e6; }
    .ps-modal .ps-modal-tab .ui-tabview .ui-tabview-nav li.ui-state-active {
      border-width: 3px;
      border-color: #2e9fe0;
      border-bottom-color: #fff; }
    .ps-modal .ps-modal-tab .ui-tabview .ui-tabview-nav li.ui-state-active + li {
      border-left-color: #2e9fe0; }
    .ps-modal .ps-modal-tab .ui-tabview .ui-tabview-nav li.ui-state-disabled {
      color: #666; }
    .ps-modal .ps-modal-tab .ui-tabview .ui-tabview-nav li:first-of-type {
      border-left: 0; }
    .ps-modal .ps-modal-tab .ui-tabview .ui-tabview-nav li.ui-state-disabled a {
      color: #aaa; }
  .ps-modal .ps-modal-tab .ui-tabview .ui-tabview-panels {
    background-color: #fff;
    box-shadow: 0px 4px 5px #d9d9d9;
    border-top: 3px solid #1ca7ff;
    margin-top: -3px; }

.ps-modal.ui-dialog .ui-dialog-footer,
.ps-modal .ui-dialog .ui-dialog-footer {
  border: 0;
  background-color: transparent;
  padding-bottom: 15px; }

.ps-modal .ps-modal-content-panel {
  background-color: #fff;
  box-shadow: 0 4px 5px #d9d9d9;
  padding: 15px;
  margin-top: -15px; }
  .ps-modal .ps-modal-content-panel.no-bgc {
    background-color: transparent; }
  .ps-modal .ps-modal-content-panel.no-drop-shadow {
    box-shadow: none; }
  .ps-modal .ps-modal-content-panel:not(.ps-pick-list-container):before {
    content: '';
    display: block;
    border-top: 3px solid #1ca7ff;
    position: sticky;
    top: -15px;
    z-index: 100;
    margin: -15px -15px 15px; }
  .ps-modal .ps-modal-content-panel.ps-pick-list-container {
    border-top: 3px solid #1ca7ff; }
  .ps-modal .ps-modal-content-panel.no-padding {
    padding: 0; }
  .ps-modal .ps-modal-content-panel.no-padding:not(.ps-pick-list-container):before {
    margin: 0; }

.ps-modal.full-content-height .ui-dialog-content {
  height: 100vh;
  padding-top: 0; }

.ps-modal.full-content-height .ps-modal-content-panel {
  height: 100%;
  margin-top: 0;
  border-top: 3px solid #1ca7ff; }
  .ps-modal.full-content-height .ps-modal-content-panel:before {
    display: none; }

.ps-modal .ps-modal-content-panel .__flex-layout {
  height: 100%;
  display: flex; }
  .ps-modal .ps-modal-content-panel .__flex-layout.wrap {
    flex-wrap: wrap; }
  .ps-modal .ps-modal-content-panel .__flex-layout > div {
    height: 100%;
    display: flex;
    flex-direction: column; }
  .ps-modal .ps-modal-content-panel .__flex-layout .__fx-sidebar-area {
    flex: 1 1 280px; }
  .ps-modal .ps-modal-content-panel .__flex-layout .__fx-main-area {
    flex: 5 1 500px; }
  .ps-modal .ps-modal-content-panel .__flex-layout .__fx-block-container {
    height: 100%;
    display: flex;
    flex-direction: column; }
  .ps-modal .ps-modal-content-panel .__flex-layout .__fx-expanded-block {
    flex: 5;
    overflow: auto; }

.ps-modal p-footer .ps-modal-more-action-btn-wrapper {
  text-align: left; }
  .ps-modal p-footer .ps-modal-more-action-btn-wrapper .ps-custom-dropdown.delete-action {
    padding: 10px; }
    .ps-modal p-footer .ps-modal-more-action-btn-wrapper .ps-custom-dropdown.delete-action .__action {
      padding: 5px 10px;
      color: #212529;
      display: block;
      -webkit-transition: background-color 0.3s ease-out;
      -o-transition: background-color 0.3s ease-out;
      transition: background-color 0.3s ease-out; }
      .ps-modal p-footer .ps-modal-more-action-btn-wrapper .ps-custom-dropdown.delete-action .__action:hover {
        background-color: #f44c4c; }

.ps-modal p-footer button.ps-modal-btn {
  margin-right: 0;
  margin-left: 5px; }

.ps-modal-p-0 .ps-page-content-header-area {
  box-shadow: 0px 0px 5px 0px #aaa;
  z-index: 100000;
  position: relative;
  padding: 0;
  margin: 0; }

.ps-modal-p-0 .__top-table-action {
  padding-bottom: 0; }

.ps-modal-p-0 .ps-modal-tab .ui-tabview .ui-tabview-panel {
  padding: 0; }

.ps-modal-p-0 .ps-modal-tab .ui-tabview .tab-panel-p .ui-tabview-panel {
  padding: 15px; }

.ps-modal-tree .ps-modal-content-panel {
  padding: 0; }

.ps-modal-tree .ui-tree-selectable.ui-widget-content {
  border: none; }

.ps-modal-tree .ps-page-content-header-area {
  padding: 10px 7.5px 0;
  background-color: #fff;
  margin-bottom: 0; }

.ps-modal-tree .__top-table-header {
  color: #000000;
  background-color: #fff; }

.ps-modal-tree .ps-data-table-area-inner {
  background-color: #dddddd; }

.ps-modal-tree .__left-table-sidebar-wrapper {
  padding-left: 0;
  overflow: hidden; }

.ps-modal-tree .__table-wrapper {
  padding: 0;
  border-left: 1px solid #1ca7ff; }

.ps-modal-tree .list-tree {
  background-color: #fff;
  height: 445px;
  overflow-y: auto; }
  .ps-modal-tree .list-tree .ui-tree-filter {
    border-radius: 0; }
  .ps-modal-tree .list-tree ul.ui-tree-container {
    height: 397px; }

.ps-modal-tree .w-20 {
  min-width: 20%; }

.ps-pick-list-container {
  display: flex; }
  .ps-pick-list-container.ps-modal-content-panel {
    padding: 0; }
  .ps-pick-list-container .ps-pick-list-left-panel, .ps-pick-list-container .ps-pick-list-right-panel {
    padding: 0;
    flex-grow: 1; }
    .ps-pick-list-container .ps-pick-list-left-panel .__panel-content-header, .ps-pick-list-container .ps-pick-list-right-panel .__panel-content-header {
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px; }
      .ps-pick-list-container .ps-pick-list-left-panel .__panel-content-header .__title, .ps-pick-list-container .ps-pick-list-right-panel .__panel-content-header .__title {
        margin-top: 0;
        margin-bottom: 15px; }
    .ps-pick-list-container .ps-pick-list-left-panel .__panel-content-body, .ps-pick-list-container .ps-pick-list-right-panel .__panel-content-body {
      min-height: 200px;
      overflow-y: auto;
      height: 500px; }
  .ps-pick-list-container .ps-pick-list-left-panel {
    border-right: 1px solid #67c8ec; }
  .ps-pick-list-container .ps-pick-list-right-panel {
    border-left: 1px solid #67c8ec; }
  .ps-pick-list-container .ps-pick-list-middle-panel {
    margin-top: 250px;
    width: 90px;
    min-width: 90px; }
    .ps-pick-list-container .ps-pick-list-middle-panel ul {
      padding: 0; }
      .ps-pick-list-container .ps-pick-list-middle-panel ul li {
        display: block; }
        .ps-pick-list-container .ps-pick-list-middle-panel ul li .__transfer-button {
          margin: 1px;
          background: #ffffff;
          color: #387bb8;
          border: 1px solid #387bb8;
          padding: 0px 18px; }
        .ps-pick-list-container .ps-pick-list-middle-panel ul li .__transfer-button:hover {
          color: #ffffff;
          background: #387bb8; }

.ps-pick-list-ul-bottom > li {
  display: inline;
  padding-right: 10px; }

.ps-search-box.style-1 {
  position: relative;
  padding-right: 30px;
  border: 1px solid #ddd; }
  .ps-search-box.style-1 .__input {
    border: 0;
    width: 100%; }
  .ps-search-box.style-1 .__search-btn,
  .ps-search-box.style-1 .__toggle-search-adv {
    display: flex;
    position: absolute;
    top: 0;
    width: 30px;
    height: 100%;
    cursor: pointer;
    align-items: center;
    justify-content: center; }
  .ps-search-box.style-1 .__toggle-search-adv {
    right: 30px; }
  .ps-search-box.style-1 .__search-btn {
    right: 0;
    color: #fff;
    background-color: #aaaaaa; }

.ps-search-box.style-2 {
  position: relative; }
  .ps-search-box.style-2 .__input {
    padding-right: 29px;
    width: 100%; }
  .ps-search-box.style-2 .__search-btn,
  .ps-search-box.style-2 .__toggle-search-adv {
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 100%;
    top: 0;
    cursor: pointer; }
  .ps-search-box.style-2 .__toggle-search-adv {
    right: 30px; }

.content-grid {
  background-color: white;
  display: flex; }
  .content-grid .new-grid-loading .ui-widget-overlay {
    z-index: 30;
    background-color: #fff; }
  .content-grid .table-grid {
    border-collapse: collapse; }
    .content-grid .table-grid td, .content-grid .table-grid th {
      border: #dddddd solid 1px; }
    .content-grid .table-grid thead {
      background: #eee; }
    .content-grid .table-grid tbody tr td:first-child {
      border-left: none; }
    .content-grid .table-grid tbody tr td:last-child {
      border-right: none; }

.grid-note {
  background-color: #fff;
  padding-top: 30px;
  padding-left: 50px;
  padding-bottom: 50px; }
  .grid-note .label-name {
    vertical-align: super;
    margin-left: 10px; }

.term {
  width: 7%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  z-index: 20;
  flex-direction: column;
  position: absolute;
  color: black;
  font-family: helvetica, sans-serif;
  padding: 0.5em;
  font-size: 0.9em;
  -webkit-transition: -webkit-box-shadow 0.15s ease-in;
  -moz-transition: -moz-box-shadow 0.15s ease-in;
  -o-transition: -o-box-shadow 0.15s ease-in;
  transition: box-shadow 0.15s ease-in; }
  .term:hover {
    box-shadow: 2px 2px 10px #444;
    -o-box-shadow: 2px 2px 10px #444;
    -webkit-box-shadow: 2px 2px 10px #444;
    -moz-box-shadow: 2px 2px 10px #444;
    opacity: 0.6; }

.status-note-normal {
  background-color: #dcf2ff;
  height: 20px;
  width: 50px;
  display: inline-block; }

.term-normal {
  background-color: #dcf2ff; }

.status-note-profession {
  background-color: #ffecde;
  height: 20px;
  width: 50px;
  display: inline-block; }

.term-profession {
  background-color: #ffecde; }

.status-note-elective {
  background-color: #ffffff;
  height: 20px;
  width: 50px;
  display: inline-block; }

.term-elective {
  background-color: #ffffff; }

.status-note-normal {
  border: 1px solid #b0e2eb; }

.term-normal {
  border: 1px solid #b0e2eb; }

.status-note-profession {
  border: 1px solid #f9d1ae; }

.term-profession {
  border: 1px solid #f9d1ae; }

.status-note-elective {
  border: 1px solid #072e7b; }

.term-elective {
  border: 1px solid #072e7b; }

.note-elective {
  position: relative; }
  .note-elective:after {
    content: " ";
    width: 100%;
    height: 4px;
    background: #072e7b;
    display: block;
    position: absolute;
    bottom: 0; }

.note-key {
  position: relative; }
  .note-key:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    border: 6px solid #ff390a;
    width: 0;
    height: 0;
    border-bottom-color: transparent;
    border-left-color: transparent; }

.elective:after {
  content: " ";
  width: 100%;
  height: 4px;
  background: #072e7b;
  display: block;
  position: absolute;
  bottom: 0; }

.term-key:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  border: 6px solid #ff390a;
  width: 0;
  height: 0;
  border-bottom-color: transparent;
  border-left-color: transparent; }

.item-child {
  left: 2%;
  width: 83px; }

.point {
  position: absolute; }

.multi-select-textarea .ui-multiselect {
  width: 100%;
  min-height: 120px;
  border-radius: 0; }
  .multi-select-textarea .ui-multiselect .ui-multiselect-trigger {
    height: auto;
    top: 16px; }
  .multi-select-textarea .ui-multiselect .ui-multiselect-label.ui-corner-all {
    display: flex;
    flex-wrap: wrap; }
    .multi-select-textarea .ui-multiselect .ui-multiselect-label.ui-corner-all .item-choose {
      width: 48%;
      color: #000;
      background-color: #d9ebf7;
      display: flex;
      border: 1px solid #dddddd;
      border-radius: 0;
      margin-right: 10px;
      line-height: 1.4;
      margin-bottom: 10px;
      align-items: center;
      justify-content: space-between; }
    .multi-select-textarea .ui-multiselect .ui-multiselect-label.ui-corner-all .full-width {
      width: 100%;
      margin: 3px auto;
      padding-left: 5px; }
  .multi-select-textarea .ui-multiselect .ui-multiselect-panel {
    display: none; }

.ps-multi-select-with-modal .ui-multiselect {
  width: 100%;
  min-height: 80px;
  border-radius: 0; }

.ps-multi-select-with-modal .ui-multiselect-trigger {
  top: 0;
  right: 0; }

.ps-multi-select-with-modal .ui-multiselect .ui-multiselect-trigger .ui-multiselect-trigger-icon {
  top: 5px;
  right: 5px;
  left: auto;
  margin: 0;
  font-size: 16px; }

.ps-multi-select-with-modal .ui-multiselect-label.ui-corner-all {
  display: flex;
  flex-wrap: wrap; }

.ps-multi-select-with-modal .selected-item {
  border: 1px solid #1ca7ff;
  background-color: rgba(28, 167, 255, 0.2);
  padding: 0 25px 0 5px;
  line-height: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
  position: relative; }
  .ps-multi-select-with-modal .selected-item .__icon {
    position: absolute;
    width: 20px;
    line-height: 20px;
    top: 0;
    text-align: center;
    right: 0;
    color: #999; }

.ps-multi-select-with-modal .ui-multiselect-panel {
  display: none; }

.ps-table-calendar-container .__top-table-action .__right-content {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap; }
  .ps-table-calendar-container .__top-table-action .__right-content .__current-time, .ps-table-calendar-container .__top-table-action .__right-content .__navigation, .ps-table-calendar-container .__top-table-action .__right-content .__view-type {
    display: inline-flex;
    padding: 0 7.5px;
    align-items: center;
    margin-bottom: 5px; }
  .ps-table-calendar-container .__top-table-action .__right-content .__current-time, .ps-table-calendar-container .__top-table-action .__right-content .__navigation {
    border-right: 1px solid #999999; }
  .ps-table-calendar-container .__top-table-action .__right-content .__navigation .ps-btn .__icon {
    font-size: 20px; }
  .ps-table-calendar-container .__top-table-action .__right-content .__change-view .ps-btn {
    margin-bottom: 0; }

.ps-table-calendar-not-hover .ui-table .ui-table-tbody > tr,
.ps-table-calendar-not-hover .ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: #fff; }
  .ps-table-calendar-not-hover .ui-table .ui-table-tbody > tr:hover,
  .ps-table-calendar-not-hover .ui-table .ui-table-tbody > tr:nth-child(even):hover {
    background-color: #fff; }

.ps-table-calendar thead.ui-table-thead {
  background: #eee; }

.ps-table-calendar .ui-table .ui-table-thead > tr > th {
  padding: 0; }

.ps-table-calendar .ui-table .ui-table-tbody > tr > td.__room-info {
  background-color: #a4d1f1; }

.ps-table-calendar .ui-table .ui-table-tbody > tr > td.__data-month {
  padding: 0;
  vertical-align: top; }

.ps-table-calendar td.__data-month .__day {
  text-align: right;
  font-size: 16px;
  padding: 8px 10px; }

.ps-table-calendar td.__data-month .__period-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; }
  .ps-table-calendar td.__data-month .__period-info .__period {
    color: #418e42;
    font-weight: normal;
    font-size: inherit;
    padding-left: 3px;
    width: 30%; }

.ps-table-calendar td.__data-month .__room-info {
  background-color: #a4d1f1;
  margin-bottom: 3px;
  padding: 5px; }
  .ps-table-calendar td.__data-month .__room-info:last-child {
    margin-bottom: 0; }

.ps-table-calendar .ui-table .ui-table-thead > tr > th,
.ps-table-calendar .ui-table .ui-table-tfoot > tr > td {
  border-color: #bbb; }

.ps-table-calendar .__day_of_week {
  display: flex;
  align-items: center; }
  .ps-table-calendar .__day_of_week .__left, .ps-table-calendar .__day_of_week .__right {
    width: 50%;
    padding: 10px; }
    .ps-table-calendar .__day_of_week .__left.__header-day, .ps-table-calendar .__day_of_week .__right.__header-day {
      width: auto; }
  .ps-table-calendar .__day_of_week .__left {
    font-size: 22px;
    color: #333333; }

.ps-table-calendar .__period-vs-day {
  position: relative;
  height: 70px; }
  .ps-table-calendar .__period-vs-day .__stroke {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute; }
    .ps-table-calendar .__period-vs-day .__stroke line {
      stroke: #bbb;
      stroke-width: 1; }
  .ps-table-calendar .__period-vs-day .__period, .ps-table-calendar .__period-vs-day .__day {
    height: 50%;
    padding: 10px;
    line-height: 15px;
    font-weight: bold;
    color: #000; }
  .ps-table-calendar .__period-vs-day .__period {
    text-align: left; }
  .ps-table-calendar .__period-vs-day .__day {
    text-align: right; }

.ps-table-calendar td .__period {
  font-size: 16px;
  color: #333333;
  font-weight: bold; }

.ps-table-calendar-room .ui-table .ui-table-thead > tr > th {
  color: #000; }

.ps-table-calendar-room .ui-table .ui-table-tbody > tr > td.__data-room {
  padding: 0; }

.ps-table-calendar-room .__day-of-week {
  font-size: 18px; }

.ps-table-calendar-room .__period-info {
  border: 1px solid #ddd;
  padding: 5px;
  min-height: 30px; }
  .ps-table-calendar-room .__period-info.__period-info-dhcq {
    background-color: #bfd6f0; }
  .ps-table-calendar-room .__period-info.__period-info-sdh {
    background-color: #f0c8bf; }
  .ps-table-calendar-room .__period-info.__period-info-vlvh {
    background-color: #fff799; }
  .ps-table-calendar-room .__period-info.__period-info-data {
    background-color: #3a8ce9; }
  .ps-table-calendar-room .__period-info.__period-info-exist {
    border: none; }
    .ps-table-calendar-room .__period-info.__period-info-exist .__icon {
      color: #0386d0;
      display: none; }
    .ps-table-calendar-room .__period-info.__period-info-exist:hover .__icon {
      display: inline; }

.ps-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-dhcq {
  background-color: #bfd6f0;
  position: relative; }

.ps-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-sdh {
  background-color: #f0c8bf;
  position: relative; }

.ps-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-vlvh {
  background-color: #fff799;
  position: relative; }

.ps-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-data {
  background-color: #3a8ce9;
  position: relative; }

.ps-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-choose {
  background-color: #3a8ce9;
  position: relative; }

.ps-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-drag {
  background-color: #82ca9c;
  position: relative; }

.ps-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-busy {
  background-color: #bbbbbb;
  position: relative; }

.ps-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-drag {
  cursor: pointer; }

.ps-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-data {
  color: #fff;
  font-weight: bold;
  cursor: pointer; }
  .ps-table-calendar-room .ui-table .ui-table-tbody > tr > td.__period-info-data.active:after {
    content: "\f058";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    right: 0;
    top: 0; }

.__period-active {
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  background-color: #3a8ce9;
  border: none;
  outline: none; }

.ps-table-scheduled-container .ui-table .ui-table-tbody > tr > td.__item-tick {
  cursor: pointer; }

.ps-table-scheduled-container .ui-table .ui-table-tbody > tr > td.__item-busy {
  background-color: #d9d9d9;
  position: relative; }

.ps-table-scheduled-container .ui-table .ui-table-tbody > tr > td.__item-click {
  background-color: #92d050;
  position: relative; }

.ps-table-scheduled-container .ui-table .ui-table-tbody > tr > td.__item-selected {
  background-color: #eb8c43;
  position: relative; }

.ps-table-scheduled-container .ui-table .ui-table-tbody > tr > td.__item-suggested {
  background-color: #73bf8b;
  position: relative; }

.ps-table-scheduled-container .ui-table .ui-table-tbody > tr > td.__item-suggestions {
  background-color: #fcf88d;
  position: relative; }

.ps-document-file {
  background: #eeeeee;
  padding: 0;
  position: relative;
  width: 180px;
  max-width: 180px;
  margin: 10px;
  flex-basis: auto; }
  .ps-document-file .__document-image {
    position: relative;
    padding: 5px; }
    .ps-document-file .__document-image .__image {
      width: 100%;
      background-repeat: no-repeat;
      height: 200px;
      background-size: cover; }
  .ps-document-file .__document-meta {
    color: #000;
    text-align: left;
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    -webkit-transition: backgroud 0.3s ease-out;
    -o-transition: backgroud 0.3s ease-out;
    transition: backgroud 0.3s ease-out; }
    .ps-document-file .__document-meta .__icon {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #2e9fe0;
      -webkit-transition: backgroud 0.3s ease-out;
      -o-transition: backgroud 0.3s ease-out;
      transition: backgroud 0.3s ease-out; }
    .ps-document-file .__document-meta .__title {
      margin-left: 5px; }
  .ps-document-file .__overlay {
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    .ps-document-file .__overlay .__icon {
      display: block;
      color: #fff;
      text-align: center;
      cursor: pointer;
      margin: 15px; }
  .ps-document-file:hover .__overlay {
    opacity: 1; }
  .ps-document-file:hover .__document-meta {
    background-color: #0386d0;
    color: #fff; }
    .ps-document-file:hover .__document-meta .__icon {
      background-color: #fff; }

.form-control-feedback {
  margin-top: -20px; }

a {
  cursor: pointer; }

.form-control-feedback {
  border: 1px solid #ffe5e5;
  background: #c51244 !important;
  padding: 2px 10px !important;
  border-radius: 0 !important;
  position: absolute;
  display: inline-block !important;
  box-shadow: 1px 1px 9px #aaa;
  margin-top: 0;
  color: #fff;
  right: -4px;
  z-index: 999999;
  white-space: nowrap;
  top: -18px;
  font-size: 12px;
  top: -16px;
  font-size: 12px;
  line-height: 14px; }

.form-control-feedback:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #c51244;
  position: absolute;
  bottom: -8px;
  right: 6px; }
