.ps-status {
    color: #fff;
    display: inline-block;
    font-size: 11px;
    border-radius: 2px;
    padding: 5px 7px;
}

@each $status, $value in (doing: $ps-status-doing,
    response: $ps-status-response,
    done: $ps-status-done,
    waiting: $ps-status-waiting,
    valid: $ps-status-valid,
    expire: $ps-status-expire,
    lock: $ps-status-lock,
    cancel: $ps-status-cancel,
    info: $ps-info-color,
    leave: $ps-status-leave,
    pause: $ps-status-pause,
    stop: $ps-status-stop,
    back: $ps-config-color) {

    // color
    .ps-status-#{$status} {
        background: $value;
    }
    .text-status-#{$status} {
        color: $value;
    }
}

