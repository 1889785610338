@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 300;
    src: url(RobotoCondensed-Light.ttf);
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 300;
    src: url(RobotoCondensed-LightItalic.ttf);
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    src: url(RobotoCondensed-Regular.ttf);
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 400;
    src: url(RobotoCondensed-Italic.ttf);
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    src: url(RobotoCondensed-Bold.ttf);
}

@font-face {
    font-family: 'Roboto Condensed';
    font-style: italic;
    font-weight: 700;
    src: url(RobotoCondensed-BoldItalic.ttf);
}
