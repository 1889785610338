body .ui-fileupload{
    border-top-left-radius   : 4px;
    border-bottom-left-radius: 4px;
}

.ui-fileupload{
    .ui-fileupload-buttonbar{
        display: none;
    }
    .ui-fileupload-content{
        background-color: #fff;
        border-style: dashed;
        border-color: $ps-base-color;
        text-align: center;
    }
    .choose-file{
        background-color: #d9ebf7;
        padding: 5px;
        border-radius: 0;
    }
}

p-fileupload.ps-file-upload.style-1 {
    .ui-fileupload{
        border-top-left-radius   : 4px;
        border-bottom-left-radius: 4px;

        .ui-fileupload-buttonbar{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row-reverse;
            border: 1px dashed #bbb;
            border-radius: 0;
            background-color: transparent;
            font-weight: 400;
            font-size: 14px;
        }

        .ui-fileupload-choose {
            position: static;
            height: 30px;
            line-height: 28px;
            padding: 0 10px;
            margin-right: 0;
            margin-left: 5px;
            border-radius: 0;
            color: $ps-text-color;
            background-color: rgba($ps-edit-color, 0.3);
            border: 1px solid rgba($ps-edit-color, 0.6);
            cursor: pointer;
            width: auto;

            input[type=file] {
                width: 100%;
                height: 100%;
            }

            .ui-button-icon-left {
                display: none;
            }

            .ui-button-text {
                padding: 0;
                display: block;
                line-height: inherit;
            }
        }

        .ui-fileupload-content{
            border: 0;
        }
    }
}
