/* You can add global styles to this file, and also import other style files */
@import './styles/variables';
@import './styles/helper/mixin';
@import './styles/core';
@import './styles/helper/helper';
@import './styles/element/element';
@import './styles/module/module';
.form-control-feedback {
    margin-top: -20px;
}
a {cursor: pointer;}

.form-control-feedback {
    border: 1px solid #ffe5e5;
    background: #c51244 !important;
    padding: 2px 10px !important;
    border-radius: 0 !important;
    position: absolute;
    display: inline-block !important;
    box-shadow: 1px 1px 9px #aaa;
    margin-top: 0;
    color: #fff;
    right: -4px;
    z-index: 999999;
    white-space: nowrap;
    top: -18px;
    font-size: 12px;
    top: -16px;
    font-size: 12px;
    line-height: 14px;
}

.form-control-feedback:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #c51244;
    position: absolute;
    bottom: -8px;
    right: 6px;
}